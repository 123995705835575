export const updateCurrentGame = (currentGame) => ({
  type: 'UPDATE_CURRENT_GAME',
  payload: currentGame,
});

export const updateChildGame = (game) => ({
  type: 'UPDATE_CHILD_GAME',
  payload: game,
});

export const updateParentGame = (game) => ({
  type: 'UPDATE_PARENT_GAME',
  payload: game,
});

export const resetGame = () => ({
  type: 'RESET_GAME',
});

export const setIsSplit = (payload) => ({
  type: 'SET_IS_SPLIT',
  payload,
});

export const setCanSplit = (payload) => ({
  type: 'SET_CAN_SPLIT',
  payload,
});

export const setCanInsurance = (payload) => ({
  type: 'SET_CAN_INSURANCE',
  payload,
});

export const setInsuranceAmount = (payload) => ({
  type: 'SET_INSURANCE_AMOUNT',
  payload,
});

export const setIsInsurance = (payload) => ({
  type: 'SET_IS_INSURANCE',
  payload,
});

export const splitGame = (payload) => ({
  type: 'SPLIT_GAME',
  payload,
});

export const rebetState = () => ({
  type: 'REBET_GAME',
  payload: null,
});

export const swapCurrentGame = () => ({
  type: 'SWAP_CURRENT_GAME',
  payload: null,
});

export const saveWalletAddress = (address) => ({
  type: 'SAVE_WALLET_ADDRESS',
  payload: address,
});

export const updateParentChildMessage = (payload) => ({
  type: 'UPDATE_PARENT_CHILD_MESSAGE',
  payload,
});

export const updateSideBet = (sideBet) => ({
  type: 'UPDATE_SIDE_BET',
  payload: sideBet,
});

export const resetBalance = (balance) => ({
  type: 'RESET_BALANCE',
  payload: balance,
});

export const setTransactionInProgress = (payload) => ({
  type: 'SET_TRANSACTION_IN_PROGRESS',
  payload,
});

export const setStandClicked = () => ({
  type: 'SET_STAND_CLICKED'
});

export const eventListenersSet = () => ({
  type: 'EVENT_LISTENERS_SET'
});

export const setInsuranceResult = (result) => ({
  type: 'SET_INSURANCE_RESULT',
  payload: result
});

export const setActiveGames = (games) => ({
  type: 'SET_ACTIVE_GAMES',
  payload: games
});

export const setBetLimits = (limits) => ({
  type: 'SET_BET_LIMITS',
  payload: limits
});

export const updatePayout = (payout) => ({
  type: 'UPDATE_PAYOUT',
  payload: payout
});

export const setDoubled = (payload) => ({
  type: 'SET_DOUBLED',
  payload,
});

export const setTransactionCancelled = (payload) => ({
  type: 'SET_TRANSACTION_CANCELLED',
  payload,
});

export const setSideBetRingBlink = (payload) => ({
  type: 'SET_SIDEBET_RING_BLINK',
  payload,
});

export const setLocale = (locale) => ({
  type: 'SET_LOCALE',
  payload: locale,
});