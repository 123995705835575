import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import { LOCALES } from '../constants';
import messages from './messages';

const Provider = ({ children, locale }) => {
  //currently only zh is available. So if locale is other than zh, set it to english
  const tmpLocale = locale !== LOCALES.CHINESE ? LOCALES.ENGLISH : locale;

  return (
    <IntlProvider
      textComponent={Fragment}
      locale={tmpLocale}
      messages={messages[tmpLocale]}
    >
      {children}
    </IntlProvider>
  );
};

Provider.displayName = 'I18nProvider';

Provider.propTypes = {
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  locale: PropTypes.oneOf(Object.values(LOCALES)),
};

Provider.defaultProps = {
  locale: LOCALES.ENGLISH,
};

export default Provider;