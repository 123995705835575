import * as THREE from 'three'
import React from 'react'
import { useThree } from 'react-three-fiber'

function Chip({ texture, geometry, offset, matSettings, color, ...props }) {
  const { gl } = useThree()
  texture.anisotropy = gl.capabilities.getMaxAnisotropy()
  texture.flipY = false
  texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
  texture.minFilter = THREE.NearestFilter

  return (
    <group position={[Math.seededRandom(offset * 2), Math.seededRandom(offset * 2), offset * 3]}>
      <mesh name="chipColor" geometry={geometry[0]}>
        <meshStandardMaterial attach="material" {...matSettings.chip} color={color} />
      </mesh>
      <mesh name="chipWhite" geometry={geometry[1]}>
        <meshStandardMaterial attach="material" {...matSettings.chip} />
      </mesh>
      <mesh name="chipTexture" geometry={geometry[2]}>
        <meshStandardMaterial attach="material" {...matSettings.chip} map={texture} />
      </mesh>
    </group>
  )
}

export default Chip