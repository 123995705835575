import * as THREE from 'three'
import React, { useRef, useMemo, memo } from 'react'
import useModel from '../helpers/useModel'
import { useTransition } from 'react-spring/three'
import { useThree } from 'react-three-fiber'
import Card from './Card'

import renderFrame from '../helpers/renderFrame'

const SplitCards = memo(({ cards, envMap, ...props }) => {
  const group = useRef()
  const [geometries] = useModel('./graphics/cards/card_new.glb')

  const { gl } = useThree()
  const backTexture = useMemo(() => new THREE.TextureLoader().load('./graphics/cardBack_latest.png'), [])
  backTexture.anisotropy = gl.capabilities.getMaxAnisotropy()

  const matSettings = {
    card: {
      color: new THREE.Color('hsl(0, 0%, 50%)'),
      roughness: 0.4,
      metalness: 0.2,
      envMap: envMap,
      envMapIntensity: 2
    },
    gold: {
      color: new THREE.Color(1.000, 0.766, 0.336),
      roughness: 0.2,
      metalness: 1,
      envMap: envMap,
      envMapIntensity: 4
    }
  }

  let animIndex = 0
  const prevCards = useRef([])
  const animatedChildCards = [];

  const setPlayerTimeout = async (card) => {
    animatedChildCards.push(card);
    if (cards.indexOf(animatedChildCards[animatedChildCards.length - 1]) === cards.length - 1) {
      await new Promise(resolve => setTimeout(resolve, 500))
      props.setPlayerAnimFinished(true);
      prevCards.current = cards;
    }
  }

  const animSplitCards = useTransition(cards, card => card.id, {
    initial: { rotation: [0, THREE.Math.degToRad(65), 0], position: [365 - 150, 18, -38] },
    from: { rotation: [0, THREE.Math.degToRad(65), 0], position: [365 - 150, 18, -38] },
    enter: card => async (next, cancel) => {
      animIndex++
      let index = card.offsetIndex
      index = index * 10

      const yPosition = 150

      if (cards.indexOf(card) === 0) {
        await next({ rotation: [0, 0, Math.PI], position: [index + 75, 10, yPosition] })
        await next({ position: [index + 75, index * 0.1, yPosition] })
        return
      }

      await new Promise(resolve => setTimeout(resolve, animIndex * 500))
      await next({ position: [365 - 185, 0, 25] })
      await next({ rotation: [0, 0, !card.id.includes('faceDown') ? Math.PI : 0], position: [index + 75, 0, yPosition] })
      await next({ position: [index + 75, index * 0.1, yPosition] })
    },
    leave: card => async (next) => {
      animIndex++
      await next({ position: [100, animIndex, -50] })
      await next({ position: [100, animIndex, -450] })
    },
    onFrame: () => {
      renderFrame()
    },
    onRest: (card, type) => {
      if (type === 'leave') return
      setPlayerTimeout(card);
      props.handleChildCardUpdate(cards.indexOf(card));
    },
    ...{ order: ['leave', 'enter', 'update'], trail: 0, lazy: false, unique: true, reset: false, config: { duration: 250 } }
  })

  return (
    <group ref={group} position={[0, 1, 0]}>
      {animSplitCards.map(({ item, key, props }) => {
        if (item.owner === 'player') {
        return <Card key={key} card={item} backTexture={backTexture} geometry={geometries} matSettings={matSettings} {...props} />
        } else {
          return null
        }
      })}
    </group>
  )
});

export default SplitCards
