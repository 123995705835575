import React from 'react';
import translate from '../../translations/Provider/translate';

const ActiveGames = ({ activeGames, parsedQuery, handleActiveGameClick }) => {
  const { origin, pathname } = window.parent.location;
  return (
    <div className="active-games-list">
      {activeGames.length ? <span>{translate("app.container.label.activeGames")}: </span> : null}
      {activeGames.map((game, index) => {
        return (
          <a
            key={game}
            className={game === parsedQuery.hash ? 'active' : ''}
            href={`${origin}${pathname}?hash=${game}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(evt) => handleActiveGameClick(evt, game)}
          >{`${index + 1}`}</a>
        );
      })}
    </div>
  );
};

export default ActiveGames;
