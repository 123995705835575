import { combineReducers } from 'redux';
import { getCookie } from '../utils';
import { LOCALES } from '../translations/Provider';

const defaultGameState = {
  gameState: null,
  message: '',
  totalBets: 0,
  playerCards: [],
  dealerCards: [],
  standClicked: false,
  transactionHash: '',
  result: null,
};

const initialState = {
  activeGames: [],
  balance: null,
  eventListeners: false,
  walletAddress: getCookie('wallet_address'),
  parentGame: defaultGameState,
  childGame: defaultGameState,
  currentGameType: 'parent',
  isSplit: false,
  canSplit: false,
  canInsurance: false,
  isDoubled: false,
  insuranceAmount: 0,
  isInsurance: false,
  insuranceResult: null,
  transactionInProgress: false,
  transactionCancelled: false,
  payout: {
    show: false,
    amount: 0
  },
  sideBet: {
    amount: 0,
    type: '', // or "21P3" or 'LuckyLucky'
    state: null, // win loose
    message: '',
    show: false
  },
  betLimits: {
    minBet: 1,
    maxBet: 100,
    minSideBet: 1,
    maxSideBet: 10
  },
  sideBetRingBlink: {
    luckyLucky: false,
    twentyOnePlusThree: false,
  },
  locale: getCookie('locale') ? getCookie('locale') : LOCALES.ENGLISH,
};

const blackjackReducer = (state = initialState, action) => {
  const currentGame =
    state.currentGameType === 'parent' ? 'parentGame' : 'childGame';
  const payload = action.payload;
  switch (action.type) {
    case 'RESET_BALANCE':
      return {
        ...state,
        balance: payload,
      };
    case 'REBET_GAME':
      return {
        ...initialState,
        [currentGame]: {
          ...initialState[currentGame],
          totalBets: state.isDoubled ? state[currentGame].totalBets / 2 : state[currentGame].totalBets,
        },
        sideBet: {
          ...state.sideBet,
          state: null,
        },
        activeGames: state.activeGames,
        betLimits: state.betLimits,
        walletAddress: getCookie('wallet_address'),
      };
    case 'UPDATE_CURRENT_GAME':
      return {
        ...state,
        [currentGame]: payload,
      };
    case 'UPDATE_CHILD_GAME':
      return {
        ...state,
        childGame: payload
      };
    case 'UPDATE_PARENT_GAME':
      return {
        ...state,
        parentGame: payload
      }
    case 'RESET_GAME':
      return {...initialState, walletAddress: getCookie('wallet_address'), activeGames: state.activeGames, betLimits: state.betLimits};
    case 'SET_IS_SPLIT':
      return {
        ...state,
        isSplit: payload,
      };
    case 'SET_CAN_SPLIT':
      return {
        ...state,
        canSplit: payload,
      };
    case 'SET_IS_INSURANCE':
      return {
        ...state,
        isInsurance: payload,
      };
    case 'SET_CAN_INSURANCE':
      return {
        ...state,
        canInsurance: payload,
      };
    case 'SET_INSURANCE_AMOUNT':
      return {
        ...state,
        insuranceAmount: payload,
      };
    case 'SPLIT_GAME':
      return payload;
    case 'SWAP_CURRENT_GAME':
      return {
        ...state,
        currentGameType: 'parent',
      };
    case 'SAVE_WALLET_ADDRESS':
      return {
        ...state,
        walletAddress: payload,
      };
    case 'SET_TRANSACTION_IN_PROGRESS':
      return {
        ...state,
        transactionInProgress: payload,
        transactionCancelled: payload ? false : state.transactionCancelled
      };
    case 'UPDATE_SIDE_BET':
      return {
        ...state,
        sideBet: {
          state: payload.state || state.sideBet.state,
          amount: payload.amount,
          type: payload.type,
          show: payload.show,
          message: payload.message || state.sideBet.message,
          payout: payload.payout
        },
      };
    case 'UPDATE_PARENT_CHILD_MESSAGE':
      return {
        ...state,
        parentGame: {
          ...state.parentGame,
          message: payload.parentMessage,
        },
        childGame: {
          ...state.childGame,
          message: payload.childMessage,
        },
      };
    case 'SET_STAND_CLICKED':
      return {
        ...state,
        [currentGame]: {
          ...state[currentGame],
          standClicked: true
        }
      }
    case 'EVENT_LISTENERS_SET':
      return {
        ...state,
        eventListeners: true
      }
    case 'SET_INSURANCE_RESULT':
      return {
        ...state,
        insuranceResult: payload
      }
    case 'SET_ACTIVE_GAMES':
      return {
        ...state,
        activeGames: payload
      }
    case 'SET_BET_LIMITS':
      return {
        ...state,
        betLimits: payload
      }
    case 'UPDATE_PAYOUT':
      return {
        ...state,
        payout: payload
      }
    case 'SET_DOUBLED':
      return {
        ...state,
        isDoubled: payload
      }
    case 'SET_TRANSACTION_CANCELLED':
      return {
        ...state,
        transactionCancelled: payload
      }
    case 'SET_SIDEBET_RING_BLINK':
      return {
        ...state,
        sideBetRingBlink: payload
      }
    case 'SET_LOCALE':
      return {
        ...state,
        locale: payload,
      }
    default:
      return state;
  }
};

export const rootReducer = combineReducers({ blackjackReducer });
