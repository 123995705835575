import React from 'react';
import {roundOfToTwoDecimals} from '../../utils';
import translate from '../../translations/Provider/translate';

const Message = ({
  animFinished,
  payout,
}) => {
  if (animFinished && payout.show) {
    return(<div className="banner-message">
      <span>{translate("app.container.msg.youWon")}&nbsp;{`${roundOfToTwoDecimals(payout.amount)} ICX!`}</span>
    </div>)
  }
  return null;
};

export default Message;
