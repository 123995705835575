import * as THREE from 'three'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSpring, a } from 'react-spring/three'
import useModel from '../helpers/useModel'
import { useThree, useFrame } from 'react-three-fiber'
import Card from './Card'

import renderFrame from '../helpers/renderFrame'

function Loading({ loadFinished, setLoadFinished, envMap, ...props }) {
  const [progress, setProgress] = useState(0)
  const [loadingDone, setLoadingDone] = useState(false)
  const [animOut, setAnimOut] = useState(false)

  const [geometries] = useModel('./graphics/cards/card_new.glb')

  const { gl } = useThree()
  const backTexture = useMemo(() => new THREE.TextureLoader().load('./graphics/cardBack_latest.png'), [])
  backTexture.anisotropy = gl.capabilities.getMaxAnisotropy()

  const tableTexture = useMemo(() => new THREE.TextureLoader().load('./graphics/table/felt.jpg'), [])
  tableTexture.flipY = false
  tableTexture.wrapS = tableTexture.wrapT = THREE.RepeatWrapping
  tableTexture.repeat.set(2.75, 2.75)
  tableTexture.offset.set(0.15, 0.225)

  const matSettings = {
    card: {
      color: new THREE.Color('hsl(0, 0%, 50%)'),
      roughness: 0.4,
      metalness: 0.2,
      envMap: envMap,
      envMapIntensity: 2
    },
    gold: {
      color: new THREE.Color(1.000, 0.766, 0.336),
      roughness: 0.2,
      metalness: 1,
      envMap: envMap,
      envMapIntensity: 4
    },
    table: {
      roughness: 0.75,
      metalness: 0,
      envMap: envMap,
      envMapIntensity: 0.5,
      map: tableTexture
    },
  }

  useEffect(() => {
    THREE.DefaultLoadingManager.onLoad = () => { setLoadingDone(true) }
    THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) =>
      setProgress(itemsLoaded / itemsTotal)
  }, [setLoadFinished])

  const { opacity } = useSpring({ opacity: loadFinished ? 0 : 1, config: { duration: 2000 } })
  const { intensity } = useSpring({ intensity: loadFinished ? 0.5 : 0.75, config: { duration: 2000 } })

  const { position_1 } = useSpring({ position_1: loadFinished ? [-5, -1, 0] : [-32.5, 0, 0] })
  const { rotation_1 } = useSpring({ rotation_1: loadFinished ? [Math.PI / 5.5, Math.PI / 50, Math.PI - 0.5] : [Math.PI / 6, 0, 0] })

  const { position_2 } = useSpring({ position_2: loadFinished ? [5, 0, 5] : [32.5, 0, 0] })
  const { rotation_2 } = useSpring({ rotation_2: loadFinished ? [Math.PI / 5.5, -Math.PI / 50, Math.PI - 0.5] : [Math.PI / 6, 0, 0] })

  const { position_3 } = useSpring({ position_3: animOut ? [0, 400, -25] : [0, 400, 475], 
    config: { duration: 500 }, onRest: (e) => {
      if (e.position_3[2] === -25) props.setRemoveSplash(true)
  }})

  const cardGroup = useRef(null)
  const initElapsedTime = useRef(null)
  const tOut = useRef(null)
  const planeMaterial = useRef(null)

  useFrame(({ clock }) => {
    if (!initElapsedTime.current) initElapsedTime.current = clock.getElapsedTime()
    const time = (clock.getElapsedTime() - initElapsedTime.current) * 3
    const roundedTime = Math.round((time % Math.PI) * 10) / 10

    if (loadingDone && !tOut.current && roundedTime === 0) {
      tOut.current = setTimeout(() => {
        setLoadFinished(true)
      }, 500)
      setTimeout(() => {
        setAnimOut(true)
      }, 1500)
    }

    cardGroup.current.rotation.x = Math.sin(time) * 0.25
    renderFrame()
  })

  return (
    <group position={[0, 200, 215]}>
      <group position={[0, 600, 600]}>
        <a.pointLight distance={1000} intensity={intensity} color="#FEF9FF"></a.pointLight>
        <mesh visible={false}>
          <sphereGeometry attach="geometry" args={[5, 16, 16]} />
          <meshBasicMaterial attach="material" />
        </mesh>
      </group>
      <a.group position={position_3} ref={cardGroup}>
        <Card card={{ value: 'J', type: 'S' }} backTexture={backTexture} geometry={geometries} matSettings={matSettings}
          position={position_1} rotation={rotation_1} />
        <Card card={{ value: 'A', type: 'S' }} backTexture={backTexture} geometry={geometries} matSettings={matSettings}
          position={position_2} rotation={rotation_2} />
      </a.group>
      <mesh position={[0, 10, 0]} rotation={[-Math.PI / 2, 0, 0]} renderOrder={1}>
        <planeGeometry attach="geometry" args={[1600, 1200]} />
        <a.meshPhysicalMaterial ref={planeMaterial} attach="material" transparent={loadFinished} opacity={opacity} {...matSettings.table}/>
      </mesh>
    </group>
  )
}

export default Loading