import React, { useEffect, useState, useMemo, Suspense, useCallback } from 'react';

import HelpOverlay from './HelpOverlay';

// import Card from './card';
import Actions from './actions';
import Message from './message';

import Graphics from '../threejs/components/Graphics';
import { connect } from 'react-redux';
import setChildEventListener from '../events/child';
import {
  createTransaction,
  askForLogin,
  getActiveGames,
  resumeGame,
  getBetLimits,
} from '../api';
import queryString from 'query-string';
import {
  updateCurrentGame,
  resetGame,
  rebetState,
  setTransactionInProgress,
  updateSideBet,
  setInsuranceAmount,
  setIsInsurance,
  saveWalletAddress,
  resetBalance,
  setStandClicked,
  setDoubled,
  setSideBetRingBlink
} from '../redux/action';
import IconService from 'icon-sdk-js';
import { getCookie, getBalance, checkSidebetWinningHand } from '../utils';
import ActiveGames from './activegames';
import { I18nProvider } from '../translations/Provider';
import translate from '../translations/Provider/translate';

const amountDivider = process.env.REACT_APP_AMOUNT_DIVIDER;

const getTotalValueObject = (cards) => {
  let containsAce = false;
  const value = cards.reduce((acc, card) => {
    if (['T', 'J', 'Q', 'K', 'L'].includes(card.value)) {
      return acc + 10;
    }
    if (card.value === 'A') {
      containsAce = true;
      return acc + 1;
    }
    return acc + Number(card.value);
  }, 0);
  return {
    containsAce,
    value,
  };
};

const renderPlayerScore = (cards, standClicked) => {
  const { containsAce, value } = getTotalValueObject(cards);
  if (standClicked) {
    return containsAce && value < 12 ? `${value + 10}` : value;
  }
  return containsAce && value < 12 ? `${value} / ${value + 10}` : value;
};

const getTransactionAmount = (amount) => {
  if (amountDivider) {
    return amount / amountDivider;
  }
  return amount;
};

const canDouble = (cards) => {
  const cardTotal = getTotalValueObject(cards).value;
  return cardTotal > 8 && cardTotal < 12;
};

const Blackjack = (props) => {
  const {
    currentGameType,
    isSplit,
    isInsurance,
    canSplit,
    canInsurance,
    insuranceAmount,
    dispatch,
    parentGame,
    childGame,
    walletAddress,
    transactionInProgress,
    sideBet,
    eventListeners,
    balance,
    activeGames,
    betLimits,
    payout,
    isDoubled,
    transactionCancelled,
    sideBetRingBlink,
    locale,
  } = props;
  const {
    gameState,
    message,
    totalBets,
    playerCards,
    dealerCards,
    standClicked,
  } = parentGame;
  const transactionHash =
    currentGameType === 'parent'
      ? parentGame.transactionHash
      : childGame.transactionHash;
  const { search } = window.parent.location;
  const parsedQuery = queryString.parse(search);

  const [loadFinished, setLoadFinished] = useState(false);
  const [selectedChip, setSelectedChip] = useState(null);

  const [playerAnimFinished, setPlayerAnimFinished] = useState(false)
  const [dealerAnimFinished, setDealerAnimFinished] = useState(false)

  const [parentPoints, setParentPoints] = useState(0);
  const [dealerPoints, setDealerPoints] = useState(0);
  const [childPoints, setChildPoints] = useState(0);

  const [showInfo, setShowInfo] = useState(null);

  const [showHelpModal, setShowHelpModal] = useState(false);

  useEffect(() => {
    setChildEventListener();
  }, []);

  useEffect(() => {
    if (eventListeners) {
      if (parsedQuery.hash) {
        resumeGame(walletAddress, parsedQuery.hash);
      }
      getBetLimits(walletAddress);
      getActiveGames(walletAddress);
    }
  }, [eventListeners]);

  useEffect(() => {
    async function getNewBalance() {
      if (
        !gameState ||
        ['initial', 'insurance', 'split'].includes(gameState) ||
        gameState === 'end' && dealerAnimFinished
      ) {
        dispatch(resetBalance(await getBalance(walletAddress)));
      }
    }
    getNewBalance();
  }, [walletAddress, gameState, dealerAnimFinished]);

  const renderSplitCase = () => {
    if (isSplit) {
      const gameState = parentGame.gameState;
      const { totalBets, message } = childGame;
      return (
        <>
          <div className="points split-points">
            <span className={`${currentGameType === 'child' ? 'active' : ''}`}>
              {childPoints}
            </span>
          </div>
          {gameState === 'end' && dealerAnimFinished ? (<div className="childbet-message">{translate(message)}</div>) : null}
          <div className="total-bets split-total-bets">
            <span>{`${totalBets} ICX`}</span>
          </div>
        </>
      );
    }
    return null;
  };

  const resetPoints = () => {
    setDealerPoints(0);
    setParentPoints(0);
    setChildPoints(0);
  };

  const handleDeal = () => {
    const mainBet = isDoubled ? totalBets / 2 : totalBets;
    //check for wallet Address and fire login event
    console.log("Wallet address", walletAddress);
    if (!walletAddress) {
      const address = getCookie('wallet_address');
      if (!address) {
        askForLogin();
        return;
      }
      dispatch(saveWalletAddress(address));
    }
    dispatch(setTransactionInProgress(true));
    createTransaction({
      walletAddress,
      params: {
        action: 'place_bet',
        side_bet_type: sideBet.type,
        side_bet_amt: IconService.IconAmount.of(
          getTransactionAmount(sideBet.amount || 0),
          IconService.IconAmount.Unit.ICX,
        )
          .toLoop()
          .toString(),
      },
      value: getTransactionAmount(mainBet + sideBet.amount),
    });
    
  };

  const handleActionClick = (actionType) => () => {
    if (transactionInProgress) {
      return false;
    }
    setPlayerAnimFinished(false);
    setDealerAnimFinished(false);
    dispatch(updateSideBet({ ...sideBet, show:false }));
    switch (actionType) {
      case 'rebet':
        dispatch(setSideBetRingBlink({ luckyLucky: false, twentyOnePlusThree: false }));
        dispatch(rebetState());
        resetPoints();
        handleDeal();
        break;
      case 'deal': {
        handleDeal();
        setSelectedChip(null);
        break;
      }
      case 'hit':
        dispatch(setTransactionInProgress(true));
        createTransaction({
          walletAddress,
          params: {
            action: isSplit
              ? 'split_hit'
              : isInsurance
              ? 'insurance_hit'
              : 'hit',
            tx_hash: transactionHash.substring(2),
          },
        });
        break;
      case 'stand':
        dispatch(setStandClicked());
        dispatch(setTransactionInProgress(true));
        createTransaction({
          walletAddress,
          params: {
            action: isSplit
              ? 'split_stand'
              : isInsurance
              ? 'insurance_stand'
              : 'stand',
            tx_hash: transactionHash.substring(2),
          },
        });
        if (isSplit) setChildPoints(renderPlayerScore(childGame.playerCards, true));
        setParentPoints(renderPlayerScore(playerCards, true));
        break;
      case 'double':
        dispatch(setDoubled(true));
        setTotalBets(totalBets * 2);
        dispatch(setTransactionInProgress(true));
        createTransaction({
          walletAddress,
          params: {
            action: 'double',
            tx_hash: transactionHash.substring(2),
          },
          value: getTransactionAmount(totalBets),
        });
        break;
      case 'insurance':
        dispatch(setTransactionInProgress(true));
        dispatch(setIsInsurance(true));
        dispatch(setInsuranceAmount(totalBets / 2));
        createTransaction({
          walletAddress,
          params: {
            action: 'insurance',
            tx_hash: transactionHash.substring(2),
          },
          value: getTransactionAmount(totalBets / 2),
        });
        setPlayerAnimFinished(true);
        setDealerAnimFinished(true);
        break;
      case 'bet':
        dispatch(setSideBetRingBlink({ luckyLucky: false, twentyOnePlusThree: false }));
        resetPoints();
        dispatch(resetGame());
        break;
      case 'split':
        dispatch(setTransactionInProgress(true));
        createTransaction({
          walletAddress,
          params: {
            action: 'split',
            tx_hash: transactionHash.substring(2),
          },
          value: getTransactionAmount(totalBets),
        });
        break;
      default:
        return;
    }
  };

  const setTotalBets = (bets) => {
    dispatch(
      updateCurrentGame({
        ...parentGame,
        totalBets: bets,
      }),
    );
  };

  const setSideBet = (sideBet) => {
    dispatch(updateSideBet(sideBet));
  };

  const handleActiveGameClick = (evt, game) => {
    if (parsedQuery.hash === game) {
      evt.preventDefault();
    }
  };

  const checkBlackjack = (cards) => {
    const {containsAce, value} = getTotalValueObject(cards);
    return cards.length === 2 && containsAce && value + 10 === 21;
  }

  const mainBetMessage = () => {
    if (!isSplit && checkBlackjack(playerCards)) {
      // player blackjack
      return <span className="blackjack-msg">BLACKJACK</span>;
    }
    return <span>{translate(message)}</span>;
  };

  const dealerBlackjackMessage = () => {
    if (gameState === 'end' && dealerAnimFinished && checkBlackjack(dealerCards)) {
      return <div className='dealer-message'>BLACKJACK</div>;
    }
    return null;
  }
  const splitPlayerCards = isSplit ? childGame.playerCards : [];

  const handleParentCardUpdate = useCallback((cardIndex) => {
    setParentPoints(renderPlayerScore(playerCards.slice(0, cardIndex + 1), standClicked));
  }, [playerCards]);

  const handleDealerCardUpdate = useCallback((cardIndex) => {
    setDealerPoints(renderPlayerScore(dealerCards.slice(0, cardIndex + 1), standClicked));
  }, [dealerCards]);

  const handleChildCardUpdate = useCallback((cardIndex) => {
    const { playerCards, standClicked } = childGame;
    setChildPoints(renderPlayerScore(playerCards.slice(0, cardIndex + 1), standClicked));
  }, [childGame.playerCards]);

  useEffect(() => {
    // Check only on initial render (playerCards count is 2) after player cards animation is finished.
    if (playerAnimFinished && playerCards.length === 2 && sideBet.state === null) {
      const sidebetWinningHand = checkSidebetWinningHand(playerCards, dealerCards);
      dispatch(setSideBetRingBlink(sidebetWinningHand));
    }
  }, [playerAnimFinished]);

  return (
    <I18nProvider locale={locale}>
      {transactionInProgress ? (
        <div className="overlay">
          <div className="loader" />
        </div>
      ) : null}
      {balance && (
        <div className="player-balance">
          {amountDivider && (
            <div className="amount-info">
              Amount is divided by {amountDivider} in Testnet
            </div>
          )}
        </div>
      )}
      <ActiveGames
        activeGames={activeGames}
        parsedQuery={parsedQuery}
        handleActiveGameClick={handleActiveGameClick}
      />

      <Message
        animFinished={dealerAnimFinished}
        payout={payout}
      />


      {loadFinished ? 
        <div className="help-button" onClick={() => setShowHelpModal(true)}>
          {translate('app.container.popup.help')}
          <span className="question-circle">?</span>
          </div> 
        : 
        null
      }

      {showHelpModal && 
        <HelpOverlay closeModal={() => setShowHelpModal(false)} />
      }

      <div className="min-max-bet">
        <span>{translate("app.container.info.maxSideBet")}{`: ${betLimits.maxSideBet} ICX`}</span>
        <span>{translate("app.container.info.maxBet")}{`: ${betLimits.maxBet} ICX`}</span>
        {balance && <span>{translate("app.container.info.balance")}: {balance} ICX</span>}
      </div>
      <div className={`game-container ${loadFinished ? '' : 'hidden'}`}>
        <div
          className={`total-bets ${gameState === null ? '' : 'moveDown'} ${
            isSplit ? 'split' : ''
          }`}
        >
          <span>{totalBets ? `${totalBets} ICX` : translate("app.container.label.placeBet")}</span>
          {isInsurance && <span>{` + ${insuranceAmount} ICX`}&nbsp;{translate("app.container.action.insurance")}</span>}
        </div>
        <div className={`total-bets twentyone`}>
          <span>
            {sideBet.type === '21P3' && sideBet.amount
              ? `${sideBet.amount} ICX`
              : translate("app.container.label.placeBet")}{' '}
            - 21 + 3
          </span>
        </div>
        {sideBet.show && playerAnimFinished ? 
          <div className="sidebet-message">
            {sideBet.state === 'lose' ?
              translate(sideBet.message)
              :
              <>{translate(sideBet.message)} &nbsp;{sideBet.payout}&nbsp; ICX</>
            }
          </div> 
          : 
          null
        }
        <div className={`total-bets luckylucky`}>
          <span>
            {sideBet.type === 'LuckyLucky' && sideBet.amount
              ? `${sideBet.amount} ICX`
              : translate("app.container.label.placeBet")}{' '}
            - {translate("app.container.label.luckyLucky")}
          </span>
        </div>
        {renderSplitCase()}
        {gameState && (
          <div className="points dealer-points">
            <span>{dealerPoints}</span>
          </div>
        )}
        {dealerBlackjackMessage()}
        {gameState && (
          <div className={`points player-points ${isSplit ? 'split' : ''}`}>
            <span
              className={`${
                currentGameType === 'parent' && isSplit ? 'active' : ''
              }`}
            >
              {parentPoints}
            </span>
          </div>
        )}
        {gameState === 'end' && dealerAnimFinished ? (<div className={`mainbet-message ${isSplit ? 'split' : ''}`}>{mainBetMessage()}</div>) : null}
        <Actions
          gameState={gameState}
          totalBets={totalBets}
          handleActionClick={handleActionClick}
          canInsurance={canInsurance}
          canSplit={canSplit}
          canDouble={canDouble(playerCards)}
          playerAnimFinished={playerAnimFinished}
          dealerAnimFinished={dealerAnimFinished}
          transactionCancelled={transactionCancelled}
        />
      </div>

      <Graphics
        totalBets={totalBets}
        setTotalBets={setTotalBets}
        standClicked={standClicked}
        playerCards={playerCards}
        dealerCards={dealerCards}
        gameState={gameState}
        selectedChip={selectedChip}
        setSelectedChip={setSelectedChip}
        loadFinished={loadFinished}
        setLoadFinished={setLoadFinished}
        playerAnimFinished={playerAnimFinished}
        setPlayerAnimFinished={setPlayerAnimFinished}
        dealerAnimFinished={dealerAnimFinished}
        setDealerAnimFinished={setDealerAnimFinished}
        isSplit={isSplit}
        splitPlayerCards={splitPlayerCards}
        setSideBet={setSideBet}
        sideBet={sideBet}
        isInsurance={isInsurance}
        showInfo={showInfo}
        setShowInfo={setShowInfo}
        parentGame={parentGame}
        childGame={childGame}
        betLimits={betLimits}
        payout={props.payout}
        handleParentCardUpdate={handleParentCardUpdate}
        handleDealerCardUpdate={handleDealerCardUpdate}
        handleChildCardUpdate={handleChildCardUpdate}
        sideBetRingBlink={sideBetRingBlink}
      />
    </I18nProvider>
  );
};

const mapStateToProps = (store) => ({
  walletAddress: store.blackjackReducer.walletAddress,
  currentGameType: store.blackjackReducer.currentGameType,
  isSplit: store.blackjackReducer.isSplit,
  isInsurance: store.blackjackReducer.isInsurance,
  insuranceAmount: store.blackjackReducer.insuranceAmount,
  canSplit: store.blackjackReducer.canSplit,
  canInsurance: store.blackjackReducer.canInsurance,
  insurance: store.blackjackReducer.insurance,
  childGame: store.blackjackReducer.childGame,
  parentGame: store.blackjackReducer.parentGame,
  transactionInProgress: store.blackjackReducer.transactionInProgress,
  sideBet: store.blackjackReducer.sideBet,
  balance: store.blackjackReducer.balance,
  eventListeners: store.blackjackReducer.eventListeners,
  activeGames: store.blackjackReducer.activeGames,
  betLimits: store.blackjackReducer.betLimits,
  payout: store.blackjackReducer.payout,
  isDoubled: store.blackjackReducer.isDoubled,
  transactionCancelled: store.blackjackReducer.transactionCancelled,
  sideBetRingBlink: store.blackjackReducer.sideBetRingBlink,
  locale: store.blackjackReducer.locale,
});

export default connect(mapStateToProps)(Blackjack);
