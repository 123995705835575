import React from 'react';
import translate from '../../translations/Provider/translate';

const Actions = ({
  gameState,
  totalBets,
  handleActionClick,
  canInsurance,
  canSplit,
  canDouble,
  playerAnimFinished,
  dealerAnimFinished,
  transactionCancelled
}) => {
  if (!gameState) {
    return (
      <div
      className={`deal-btn ${!totalBets ? 'inactive' : ''}`}
      onClick={handleActionClick('deal')}
      >
        {translate("app.container.action.deal")}
      </div>
    );
  }
  return (
    <div
      className={"action-btns d-flex justify-content-center"}
    >
      {
        playerAnimFinished || transactionCancelled ? 
        <>
          {['initial'].includes(gameState) && canDouble && (
            <div className="action-stand" onClick={handleActionClick('double')}>
              {translate("app.container.action.double")}
            </div>
          )}
          {[
            'initial',
            'hit',
            'insurance',
            'insurancehit',
            'split',
            'splithit',
          ].includes(gameState) && (
            <div className="action-hit" onClick={handleActionClick('hit')}>
              {translate("app.container.action.hit")}
            </div>
          )}
          {[
            'initial',
            'hit',
            'insurance',
            'insurancehit',
            'split',
            'splithit',
          ].includes(gameState) && (
            <div className="action-stand" onClick={handleActionClick('stand')}>
              {translate("app.container.action.stand")}
            </div>
          )}
          {['initial'].includes(gameState) && canInsurance && (
            <div className="action-stand" onClick={handleActionClick('insurance')}>
              {translate("app.container.action.insurance")}
            </div>
          )}
          {['initial'].includes(gameState) && canSplit && (
            <div className="action-stand" onClick={handleActionClick('split')}>
              {translate("app.container.action.split")}
            </div>
          )}
        </> : null
      }
      {
        dealerAnimFinished ? 
        <>
          {['end'].includes(gameState) && (
            <div className="action-stand" onClick={handleActionClick('bet')}>
              {translate("app.container.action.bet")}
            </div>
          )}
          {['end'].includes(gameState) && (
            <div className="action-stand" onClick={handleActionClick('rebet')}>
              {translate("app.container.action.rebet")}
            </div>
          )}
        </> : null
      }
    </div>
  );
};

export default Actions;
