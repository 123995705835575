const renderFrame = () => {
  if (window.gl) {
    if (!window.previousTime) window.previousTime = 0

    const currentTime = Date.now()
    const delta = currentTime - window.previousTime

    if (delta > (1000 / 45)) {
      window.previousTime = currentTime
    } else {
      return
    }

    window.gl.render(window.scene, window.camera)    
  }
}

export default renderFrame