import * as THREE from 'three'
import React, { useRef, useMemo, memo, useCallback } from 'react'
import useModel from '../helpers/useModel'
import { useTransition, useSpring, a } from 'react-spring/three'
import { useThree } from 'react-three-fiber'
import Card from './Card'

import renderFrame from '../helpers/renderFrame'

const zipArrays = (a, b) => a.length ? [a[0], ...zipArrays(b, a.slice(1))] : b;

const useAnimCards = (cards, props, playerCards, dealerCards) => {
  const animatedPlayerCards = [];
  const animatedDealerCards = [];
  let animIndex = 0
  const prevCards = useRef([])
  const removedCards = useRef([])

  const setPlayerTimeout = async (card) => {
    animatedPlayerCards.push(card);
    if (playerCards.indexOf(animatedPlayerCards[animatedPlayerCards.length - 1]) === playerCards.length - 1) {
      await new Promise(resolve => setTimeout(resolve, 500))
      props.setPlayerAnimFinished(true);
      prevCards.current = cards;
    }
  }

  const setDealerTimeout = async (card) => {
    animatedDealerCards.push(card);
    if (dealerCards.indexOf(animatedDealerCards[animatedDealerCards.length - 1]) === dealerCards.length - 1 && card.value !== null) {
      await new Promise(resolve => setTimeout(resolve, 1200))
      props.setDealerAnimFinished(true);
      prevCards.current = cards;
    }
  }

  const animCards = useTransition(cards, card => card.id, {
    initial: { rotation: [0, THREE.Math.degToRad(65), 0], position: props.isSplit && ['split', 'splithit'].includes(props.gameState) ? [290, 18, -38] : [365 - 150, 18, -38] },
    from: { rotation: [0, THREE.Math.degToRad(65), 0], position: props.isSplit && ['split', 'splithit'].includes(props.gameState) ? [290, 18, -38] : [365 - 150, 18, -38] },
    update: card => async (next) => {
      const match = prevCards.current.filter(prevCard => prevCard.id === card.id)
      if (match.length) {
        let same = true
        for (let [key, value] of Object.entries(match[0])) {
          if (same && card[key] === value) same = true
          else same = false
        }
        if (same && playerCards.length === 1 && playerCards[0].id === card.id) {
          // To update score of parent card after split
          props.handleParentCardUpdate(playerCards.indexOf(card));
        }
        if (same) {
          return
        }
      }

      if (match.length && match[0].value === card.value && cards.indexOf(card) !== 0) {
        return
      }
      animIndex++

      let index = card.offsetIndex
      index = index * 10
      const faceDownMult = (card.id.includes('faceDown') && !card.value) ? 6.5 : 1
      const yPosition = card.owner === 'player' ? 150 : -50

      await new Promise(resolve => setTimeout(resolve, animIndex * 600))
      await next({ rotation: [0, 0, !card.id.includes('faceDown') ? Math.PI : 0], position: [index * faceDownMult, 0, yPosition] })
      await next({ position: [index * faceDownMult, index * 0.1, yPosition] })

      if (card.id.includes('faceDown') && card.value) await next({ rotation: [0, 0, Math.PI], position: [index, 25, yPosition] })
      if (card.id.includes('faceDown') && card.value) await next({ position: [index, index * 0.1, yPosition] })
   
      if (card.owner === 'player') {
        setPlayerTimeout(card);
      }
      else setDealerTimeout(card);
    },
    enter: card => async (next, cancel) => {
      const faceDownIndex = cards.findIndex(card => card.id.includes('faceDown'));
      removedCards.current = removedCards.current.filter(
        (removedCard) => removedCard.id !== card.id,
      );
      animIndex++

      let index = card.offsetIndex
      index = index * 10
      const faceDownMult = (card.id.includes('faceDown') && !card.value) ? 6.5 : 1
      const yPosition = card.owner === 'player' ? 150 : -50
      if (cards.indexOf(card) > faceDownIndex) {
        // Delay animation for card next to facedown card to wait for facedown card flip
        animIndex = animIndex + 2;
      }
      await new Promise(resolve => setTimeout(resolve, animIndex * 500))
      if (props.gameState === 'split' && card.owner === 'player') await new Promise(resolve => setTimeout(resolve, 500))
      if (faceDownMult === 1 ) await next({ position: [365 - 185, 0, 25] }) // Animate out of the shoe
      await next({ rotation: [0, 0, !card.id.includes('faceDown') ? Math.PI : 0], position: [index * faceDownMult, 0, yPosition] })
      await next({ position: [index * faceDownMult, index * 0.1, yPosition] })

      if (card.id.includes('faceDown') && card.value) await next({ rotation: [0, 0, Math.PI], position: [index, 25, yPosition] })
      if (card.id.includes('faceDown') && card.value) await next({ position: [index, index * 0.1, yPosition] })
    },
    leave: (card) => async (next) => {
      const match = removedCards.current.filter(
        (removedCard) => removedCard.id === card.id,
      );

      const index = prevCards.current.map(card => card.id).indexOf(card.id)
      if (match.length && !(index === 2 || card.value === 'A')) return
      removedCards.current.push(card);

      animIndex++
      if (props.isSplit) {
        await next({ position: [0, -5, 150] })
      } else {
        await next({ position: [100, animIndex, -50] })
        await next({ position: [100, animIndex, -450] })
      }
    },
    onFrame: () => {
      renderFrame()
    },
    onRest: (card, type) => {
      if (type === 'leave') return
      if (card.owner === 'player') {
        setPlayerTimeout(card);
        props.handleParentCardUpdate(playerCards.indexOf(card));
      }
      else {
        setDealerTimeout(card);
        props.handleDealerCardUpdate(dealerCards.indexOf(card));
      }
    },
    ...{
      order: ['from', 'leave', 'enter', 'update'],
      trail: 0,
      lazy: false,
      unique: true,
      reset: false,
      config: { duration: 250 },
    },
  });

  return animCards;
};

const Cards = memo(({ playerCards, dealerCards, envMap, cardImgArray, ...props }) => {
  const group = useRef();
  const [geometries] = useModel('./graphics/cards/card_new.glb');

  const { gl } = useThree();
  const backTexture = useMemo(
    () => new THREE.TextureLoader().load('./graphics/cardBack_latest.png'),
    [],
  );
  backTexture.anisotropy = gl.capabilities.getMaxAnisotropy();

  const matSettings = {
    card: {
      color: new THREE.Color('hsl(0, 0%, 50%)'),
      roughness: 0.4,
      metalness: 0.2,
      envMap: envMap,
      envMapIntensity: 2,
    },
    gold: {
      color: new THREE.Color(1.0, 0.766, 0.336),
      roughness: 0.2,
      metalness: 1,
      envMap: envMap,
      envMapIntensity: 4,
    },
  };

  let cards = [...playerCards, ...dealerCards]
  if (['initial', 'end'].includes(props.gameState) && playerCards.length === 2) cards = zipArrays(playerCards, dealerCards)

  const animCards = useAnimCards(cards, props, playerCards, dealerCards)
  const { splitGamePos } = useSpring({ splitGamePos: props.isSplit ? [-75, 0, 0] : [0, 0, 0], onFrame: () => renderFrame() })

  return (
    <group ref={group} position={[0, 1, 0]}>
      <a.group position={splitGamePos}>
        {animCards.map(({ item, key, props }) => {
          if (item.owner === 'player') {
            return (
              <Card
                key={key}
                card={item}
                backTexture={backTexture}
                geometry={geometries}
                matSettings={matSettings}
                cardImgArray={cardImgArray}
                {...props}
              />
            );
          } else {
            return null;
          }
        })}
      </a.group>
      {animCards.map(({ item, key, props }) => {
        if (item.owner === 'dealer') {
          return (
            <Card
              key={key}
              card={item}
              backTexture={backTexture}
              geometry={geometries}
              matSettings={matSettings}
              cardImgArray={cardImgArray}
              {...props}
            />
          );
        } else {
          return null;
        }
      })}
    </group>
  );
});

export default Cards;
