/* eslint-disable react-hooks/exhaustive-deps */
import * as THREE from 'three'
import React, { Suspense, useState, useRef, useMemo, useEffect, useLayoutEffect } from 'react'
import { Canvas, useThree, useFrame } from 'react-three-fiber'
import { useSpring, a } from 'react-spring/three'
import './Graphics.scss';

import TableGeometry from './TableGeometry';
import Cards from './Cards';
import SplitCards from './SplitCards';
import Chips from './Chips';
import { BetStack, BetRing } from './BetStack';
import Loading from './Loading';

import renderFrame from '../helpers/renderFrame'

Math.seededRandom = function (value) {
  const output = (((Math.sin(value) / 2 + 0.5) * 10000) % 100) / 100
  return output * 2 - 1;
};

const Camera = () => {
  const camera = useRef()
  const { size, setDefaultCamera } = useThree()
  useEffect(() => void setDefaultCamera(camera.current), [])
  useFrame(({ gl, scene, camera }) => { }, 1)

  return (
    <perspectiveCamera
      ref={camera}
      aspect={size.width / size.height}
      radius={(size.width + size.height) / 4}
      near={1}
      far={20000}
      onUpdate={(self) => self.updateProjectionMatrix()}
    />
  );
};

const SetupCamera = () => {
  const { gl, scene, camera } = useThree()

  window.gl = gl
  window.scene = scene
  window.camera = camera

  camera.position.set(0, 955.544, 1040.66)
  camera.rotation.set(-Math.PI / 4, 0, 0)
  camera.setFocalLength(85)
  return null
}

const Graphics = ({ setTotalBets, totalBets, playerCards, splitPlayerCards, dealerCards, selectedChip, setSelectedChip, sideBet,
  setSideBet, isInsurance, setPlayerAnimFinished, setDealerAnimFinished, parentGame, childGame, playerAnimFinished, dealerAnimFinished, betLimits, payout, sideBetRingBlink, ...props }) => {
  const base = 'graphics/studio/small';
  const reflCube_sharp = {
    urls: [
      base + '/px.jpg',
      base + '/nx.jpg',
      base + '/py.jpg',
      base + '/ny.jpg',
      base + '/pz.jpg',
      base + '/nz.jpg',
    ],
  };

  const envMap = useMemo(
    () => new THREE.CubeTextureLoader().load(reflCube_sharp.urls),
    [],
  );
  const { minBet, maxBet, minSideBet, maxSideBet } = betLimits;
  const [currentBet, setCurrentBet] = useState([])
  const [currentSideBet, setCurrentSideBet] = useState([])
  const [currentInsurance, setCurrentInsurance] = useState([])
  const [winChips, setWinChips] = useState([])
  const [winSidebetChips, setWinSidebetChips] = useState([])
  const [isLuckyLuckyRingShown, setLuckyLuckyRingShown] = useState(true);
  const [isTwentyOnePlusThreeShown, setTwentyOnePlusThreeShown] = useState(true);
  
  const [removeSplash, setRemoveSplash] = useState(false)

  const chips = [0.2, 0.5, 1, 5, 10, 25, 100];
  const generateChipStack = (amount) => {
    let denominations = [...chips];
    let result = [];
    while (amount > 0) {
      let chip = denominations.pop();
      let count = Math.floor(amount / chip);
      amount -= count * chip;
      amount = Math.round(amount * 10) / 10
      for (let i = 0; i < count; i++) {
        result.push(chips.indexOf(chip));
      }
    }

    return result;
  };

  const onAddChip = (coinValue, stackType) => {
    if (selectedChip !== null && !props.gameState) {
      if (stackType.includes('default')) {
        const newBet = totalBets + coinValue;
        if(newBet > maxBet || newBet < minBet) {
          return;
        }
        setTotalBets(totalBets + coinValue);
      } else {
        const newSideBet = sideBet.amount + coinValue;
        if(newSideBet > maxSideBet || newSideBet < minSideBet) {
          return;
        }
        setSideBet({ amount: newSideBet, type: stackType });
      }
    }
  };

  const onClearChips = (stackType) => {
    if (!props.gameState) {
      if (stackType.includes('default')) {
        setTotalBets(0);
      } else {
        setSideBet({ amount: 0, type: '' });
      }
    }
  };

  useEffect(() => {
    if (!totalBets) setCurrentBet([]);
    else setCurrentBet(generateChipStack(totalBets));
  }, [totalBets]);

  useEffect(() => {
    if (!sideBet.amount) setCurrentSideBet([]);
    else setCurrentSideBet(generateChipStack(sideBet.amount));
  }, [sideBet]);

  useEffect(() => {
    if (isInsurance) setCurrentInsurance(generateChipStack(totalBets / 2));
  }, [isInsurance]);

  useEffect(() => {
    const amount = childGame.gameState ? payout.amount / 2 : payout.amount
    const subtractMult = (parentGame.result === 'INSURANCEWIN') ? 0.5 : 1
    if (payout.show) setWinChips(generateChipStack(amount - totalBets * subtractMult));
  }, [payout, parentGame]);

  const [showSideBetChips, setShowSideBetChips] = useState(true)
  const [prevSideBet, setPrevSideBet] = useState(null)
  const [gameEnded, setGameEnded] = useState(false)
  useEffect(() => {
    if (sideBet.state === 'win' && dealerAnimFinished) setWinSidebetChips(generateChipStack(sideBet.payout - sideBet.amount));
    if (sideBet.state === 'lose' && dealerAnimFinished) setWinSidebetChips([]);

    if ((sideBet.state === 'win' || sideBet.state === 'lose') && !prevSideBet) setPrevSideBet({ amount: sideBet.amount, type: sideBet.type });
   
    if (prevSideBet && !sideBet.show && sideBet.amount && sideBet.state !== 'win') setShowSideBetChips(false)
    if (props.gameState === 'initial') setGameEnded(false)
    if (parentGame.result) setGameEnded(true)
    if (gameEnded && props.gameState === null) setWinSidebetChips([]);

    if (sideBet.state === null && prevSideBet) {
      setShowSideBetChips(true)
      setPrevSideBet(null);
    }
  }, [sideBet, dealerAnimFinished, prevSideBet]);

  const splitPlayerCardsWithID = useMemo(() => {
    if (splitPlayerCards.length) {
      splitPlayerCards.forEach((card, index) => {
        card.owner = 'player'
        card.offsetIndex = index
      })

      return splitPlayerCards;
    } else {
      return [];
    }
  }, [splitPlayerCards]);

  const playerCardsWithID = useMemo(() => {
    if (playerCards.length) {
      playerCards.forEach((card, index) => {
        card.owner = 'player'
        card.offsetIndex = index
      })

      return playerCards;
    } else {
      return [];
    }
  }, [playerCards]);

  const gameCounter = useRef(0);
  const downId = useRef(`faceDown-${gameCounter.current}`);

  useEffect(() => {
    if (props.gameState === 'end')
      gameCounter.current = gameCounter.current + 1;
    downId.current = `faceDown-${gameCounter.current}`;

    if (props.gameState === 'initial') {
      setPlayerAnimFinished(false)
      setDealerAnimFinished(false)
    }
  }, [props.gameState]);

  const addFacedownCard = () => {
    if (prevDealerCards.current.filter(card => card.id.includes('faceDown')).length === 0) {
      prevDealerCards.current.push({ value: null, type: null, owner: "dealer", id: downId.current, offsetIndex: 1 })
    }
  }

  const prevDealerCards = useRef([]);
  const dealerCardsWithID = useMemo(() => {
    if (props.gameState === 'initial') prevDealerCards.current = []

    if (dealerCards.length) {
      dealerCards.forEach((card, index) => {
        card.owner = 'dealer'
        card.offsetIndex = index
        if (index === 1) card.id = downId.current
      })

      prevDealerCards.current = [...dealerCards]
      if (prevDealerCards.current.length) addFacedownCard()
      return prevDealerCards.current;
    } else {
      return [];
    }
  }, [dealerCards, props.standClicked]);
  
  const chipTextures = useMemo(() => {
    const loader = new THREE.TextureLoader();
    const chipTextures = [
      loader.load(`./graphics/chips/0.2.png`),
      loader.load(`./graphics/chips/0.5.png`),
      loader.load(`./graphics/chips/1.png`),
      loader.load(`./graphics/chips/5.png`),
      loader.load(`./graphics/chips/10.png`),
      loader.load(`./graphics/chips/25.png`),
      loader.load(`./graphics/chips/100.png`)
    ]
    return chipTextures
  }, [])

  const offscreenMult = -750
  
  let mainStackAnim = 0
  let mainStackAnimPrev = useRef(0)
  if ((parentGame.result === 'LOSE' || parentGame.result === 'SPLITLOSE' || parentGame.result === 'INSURANCEWIN') && (dealerAnimFinished)) mainStackAnim = 1

  let splitStackAnim = 0
  let splitStackAnimPrev = useRef(0)
  if ((childGame.result === 'LOSE' || childGame.result === 'SPLITLOSE' || childGame.result === 'INSURANCEWIN') && (dealerAnimFinished)) splitStackAnim = 1

  let splitStackWinAnim = 0
  let splitStackWinAnimPrev = useRef(0)
  if ((parentGame.result === 'LOSE' || parentGame.result === 'SPLITLOSE') && (playerAnimFinished || dealerAnimFinished)) splitStackWinAnim = 1

  let insuranceStackAnim = 0
  let insuranceStackAnimPrev = useRef(0)
  if ((parentGame.result === 'LOSE' || parentGame.result === 'SPLITLOSE' || parentGame.result === 'WIN') && (playerAnimFinished || dealerAnimFinished)) insuranceStackAnim = 1

  let sideBetStackAnim = 0
  let sideBetStackAnimPrev = useRef(0)
  if (sideBet.state === 'lose' && dealerAnimFinished) sideBetStackAnim = 1

  const { chipPos } = useSpring({ chipPos: props.gameState === null ? 0 : 60, onFrame: () => renderFrame() })
  const { chipExtraAnim } = useSpring({ chipExtraAnim: props.gameState === null ? 0 : 20, onFrame: () => renderFrame() })
  
  const { mainStackPos } = useSpring({ mainStackPos: props.isSplit ? [-75, 0, 0] : [0, 0, 0], onFrame: () => renderFrame() })
  const { mainStackResultPos } = useSpring({
    from: { mainStackResultPos: [0, 0, 0] }, 
    to: [
      mainStackAnimPrev.current === 0 && { mainStackResultPos: [0, mainStackAnim * 10, 0] }, 
      { mainStackResultPos: [0, mainStackAnim * 10, mainStackAnim * offscreenMult] }
    ],
    onFrame: () => renderFrame(),
    onStart: () => mainStackAnimPrev.current = mainStackAnim
  })
  const { splitStackPos } = useSpring({ splitStackPos: props.isSplit ? [75, 0, 0] : [0, 0, 0], onFrame: () => renderFrame() })
  const { splitStackResultPos } = useSpring({ 
    from: { splitStackResultPos: [0, 0, 0] }, 
    to: [
      splitStackAnimPrev.current === 0 && { splitStackResultPos: [0, splitStackAnim * 10, 0] },
      { splitStackResultPos: [0, splitStackAnim * 10, splitStackAnim * offscreenMult] },
    ], 
    onFrame: () => renderFrame(),
    onStart: () => splitStackAnimPrev.current = splitStackAnim
  })
  const { splitStackWinResultPos } = useSpring({
    from: { splitStackWinResultPos: [0, 0, 0] },
    to: [
      splitStackWinAnimPrev.current === 0 && { splitStackWinResultPos: [0, splitStackWinAnim * 10, 0] },
      { splitStackWinResultPos: [0, splitStackWinAnim * 10, splitStackWinAnim * offscreenMult] },
    ],
    onFrame: () => renderFrame(),
    onStart: () => splitStackWinAnimPrev.current = splitStackWinAnim
  })
  const { insurancePos } = useSpring({
    from: { insurancePos: isInsurance ? [0, 0, 0] : [0, 0, 100] },
    to: [ 
      insuranceStackAnimPrev.current === 0 && { insurancePos: isInsurance ? [0, insuranceStackAnim * 10, 0] : [0, insuranceStackAnim * 10, 100] },
      { insurancePos: isInsurance ? [0, insuranceStackAnim * 10, insuranceStackAnim * offscreenMult] : [0, insuranceStackAnim * 10, insuranceStackAnim * offscreenMult + 100] },
    ],
    onFrame: () => renderFrame(),
    onStart: () => insuranceStackAnimPrev.current = insuranceStackAnim
  })
  const { sideBetPos } = useSpring({
    from: { sideBetPos: [0, 0, 0] },
    to: [
      sideBetStackAnimPrev.current === 0 && { sideBetPos: [0, sideBetStackAnim * 10, 0] },
      { sideBetPos: [0, sideBetStackAnim * 10, sideBetStackAnim * offscreenMult] },
    ],
    onFrame: () => renderFrame(),
    onStart: () => sideBetStackAnimPrev.current = sideBetStackAnim
  })

  const makeUrl = file => `./graphics/cards/${file}.png`
  const [cardImgArray, setCardImgArray] = useState(null)
  useEffect(() => {
    const cardImgArray = {}
    const loader = new THREE.TextureLoader()
    const urls = ['10C', '2C', '3C', '4C', '5C', '6C', '7C', '8C', '9C', 'AC', 'JH', 'KH', 'QH', '10D', '2D', '3D', '4D', '5D', '6D', '7D', '8D', '9D', 'AD', 'JS', 'KS', 'QS', '10H', '2H', '3H', '4H', '5H', '6H', '7H', '8H', '9H', 'AH', 'JC', 'KC', 'QC', '10S', '2S', '3S', '4S', '5S', '6S', '7S', '8S', '9S', 'AS', 'JD', 'KD', 'QD']
        
    let counter = 0
    urls.forEach(url => {
      loader.load(makeUrl(url), (texture) => {
        cardImgArray[url] = texture
        counter++
        if (counter === urls.length) setCardImgArray(cardImgArray)
      })
    })
  }, []);
  
  const twentyOnePlusThreeInterval = useRef(false);
  const luckyLuckyInterval = useRef(false);

  const blinkLuckyLucky = () => {
    let count = 0;
    luckyLuckyInterval.current = setInterval(() => {
      if (count > 5) {
        clearInterval(luckyLuckyInterval.current);
      } else if (count % 2 === 0) {
        setLuckyLuckyRingShown(false);
      }
      else setLuckyLuckyRingShown(true);
      count ++;
    }, 350);
  }

  const blinkTwentyOnePlusThree = () => {
    let count = 0;
    twentyOnePlusThreeInterval.current = setInterval(() => {
      if (count > 5) {
        clearInterval(twentyOnePlusThreeInterval.current);
      } else if (count % 2 === 0) {
        setTwentyOnePlusThreeShown(false);
      }
      else setTwentyOnePlusThreeShown(true);
      count ++;
    }, 350);
  }

  useEffect(() => {
    const {luckyLucky, twentyOnePlusThree} = sideBetRingBlink;
    if (luckyLucky) blinkLuckyLucky();
    if (twentyOnePlusThree) blinkTwentyOnePlusThree();
  }, [sideBetRingBlink.twentyOnePlusThree, sideBetRingBlink.luckyLucky]);

  return (
    <div className="graphics-container">
      <Canvas gl={{ alpha: true, antialias: true }} >
        <group
          onPointerMove={() => renderFrame()}
          scale={[1, 1, 1]}
          rotation={[Math.PI / 1.5, 0, 0]}
        >
          <Camera />
          <SetupCamera />

          <ambientLight intensity={0.5} color="#F3F2FF" />
          <group position={[0, 400, -350]}>
            <pointLight distance={2000} intensity={0.7} color="#FEF9FF"></pointLight>
            {/* <mesh visible={false}>
              <sphereGeometry attach="geometry" args={[5, 16, 16]} />
              <meshBasicMaterial attach="material" />
            </mesh> */}
          </group>

          <Suspense fallback={null}>
            {!removeSplash && <Loading envMap={envMap} loadFinished={props.loadFinished} setLoadFinished={props.setLoadFinished} setRemoveSplash={setRemoveSplash} />}
          </Suspense>

          <Suspense fallback={null}>
            <TableGeometry envMap={envMap} showInfo={props.showInfo} />
            {cardImgArray && <>
            <Cards cardImgArray={cardImgArray} envMap={envMap} playerCards={playerCardsWithID} dealerCards={dealerCardsWithID} isSplit={props.isSplit} 
              setPlayerAnimFinished={setPlayerAnimFinished} setDealerAnimFinished={setDealerAnimFinished} gameState={props.gameState} handleParentCardUpdate={props.handleParentCardUpdate} handleDealerCardUpdate={props.handleDealerCardUpdate} />
            <SplitCards cardImgArray={cardImgArray} envMap={envMap} cards={splitPlayerCardsWithID} isSplit={props.isSplit} setPlayerAnimFinished={setPlayerAnimFinished} handleChildCardUpdate={props.handleChildCardUpdate} />
            <a.group position={chipPos.interpolate(chipPos => [0, 0, chipPos])}>
              <a.group position={chipExtraAnim.interpolate(chipPos => [0, 0, chipPos])}>
                <Chips selectedChip={selectedChip} setSelectedChip={setSelectedChip} chipTextures={chipTextures} envMap={envMap} />
              </a.group>
              {/* MAIN CHIPS */}
              {props.loadFinished && (
                <a.group position={mainStackPos}>
                    {!parentGame.result && 
                      <BetStack
                        stackType="default"
                        currentBet={currentBet}
                        chipTextures={chipTextures}
                        envMap={envMap}
                        position={[0, 1, 180]}
                        sideBet={sideBet}
                      />
                    }
                  }
                  <BetRing
                    stackType="default"
                    onAddChip={onAddChip}
                    onClearChips={onClearChips}
                    selectedChip={selectedChip}
                    currentBet={currentBet}
                    chipTextures={chipTextures}
                    envMap={envMap}
                    gameState={props.gameState}
                    position={[0, 1, 180]}
                    sideBet={sideBet}
                  />
                  {/* CHIP ANIM FOR LOSE OR WIN */}
                  {parentGame.result && 
                    <a.group position={mainStackResultPos}>
                      <BetStack
                        stackType="default"
                        currentBet={currentBet}
                        chipTextures={chipTextures}
                        envMap={envMap}
                        position={[0, 1, 180]}  
                        sideBet={sideBet}
                      />
                    </a.group>
                  }
                  {/* EXTRA CHIP ANIM FOR WIN */}
                  {props.loadFinished && dealerAnimFinished && (parentGame.result && parentGame.result.includes('WIN')) && (
                    <a.group position={splitStackWinResultPos}>
                      <BetStack
                        stackType="default"
                        currentBet={winChips}
                        chipTextures={chipTextures}
                        envMap={envMap}
                        position={[-50, 1, 180]}
                        sideBet={sideBet}
                      />
                    </a.group>
                  )}
                </a.group>
              )}
              {/* SPLIT CHIPS */}
              {props.loadFinished && props.isSplit && (
                <a.group position={splitStackPos}>
                  {!childGame.result &&
                    <BetStack
                      stackType="default_split"
                      currentBet={currentBet}
                      chipTextures={chipTextures}
                      envMap={envMap}
                      position={[0, 1, 180]}
                      sideBet={sideBet}
                    />
                  }
                  <BetRing
                    stackType="default_split"
                    onAddChip={onAddChip}
                    onClearChips={onClearChips}
                    selectedChip={selectedChip}
                    currentBet={currentBet}
                    chipTextures={chipTextures}
                    envMap={envMap}
                    gameState={props.gameState}
                    position={[0, 1, 180]}
                    sideBet={sideBet}
                  />
                  {/* SPLIT CHIP ANIM FOR LOSE OR WIN */}
                  {childGame.result &&
                    <a.group position={splitStackResultPos}>
                      <BetStack
                        stackType="default_split"
                        currentBet={currentBet}
                        chipTextures={chipTextures}
                        envMap={envMap}
                        position={[0, 1, 180]}
                        sideBet={sideBet}
                      />
                    </a.group>
                  }
                  {/* SPLIT EXTRA CHIP ANIM FOR WIN */}
                  {props.loadFinished && (playerAnimFinished || dealerAnimFinished) && (parentGame.result && parentGame.result.includes('WIN')) && (
                    <a.group position={splitStackResultPos}>
                      <BetStack
                        stackType="default_split"
                        currentBet={winChips}
                        chipTextures={chipTextures}
                        envMap={envMap}
                        position={[-50, 1, 180]}
                        sideBet={sideBet}
                      />
                    </a.group>
                  )}
                </a.group>
              )}
              {/* INSURANCE CHIPS */}
              {props.loadFinished && (
                <a.group position={insurancePos} visible={isInsurance}>
                  <BetStack
                    stackType="default"
                    currentBet={currentInsurance}
                    chipTextures={chipTextures}
                    envMap={envMap}
                    position={[-100, 1, 180]}
                    sideBet={sideBet}
                  />
                </a.group>
              )}
            </a.group>
            {!sideBet.state &&
              <BetStack
                stackType="21P3"
                currentBet={currentSideBet}
                chipTextures={chipTextures}
                envMap={envMap}
                showSideBetChips={showSideBetChips}
                position={[-215, 1, 100 - 50]}
                sideBet={sideBet}
              />
            }
            {sideBet.state &&
              <a.group position={sideBetPos}>
                <BetStack 
                  stackType="21P3" 
                  currentBet={currentSideBet} 
                  chipTextures={chipTextures} 
                  envMap={envMap} 
                  showSideBetChips={showSideBetChips}
                  position={[-215, 1, 100 - 50]} 
                  sideBet={sideBet}
                />
              </a.group>
            }
            <BetRing 
              stackType="21P3" 
              onAddChip={onAddChip} 
              onClearChips={onClearChips} 
              selectedChip={selectedChip}
              currentBet={currentSideBet} 
              chipTextures={chipTextures} 
              envMap={envMap}
              gameState={props.gameState} 
              position={[-215, 1, 100 - 50]} 
              sideBet={sideBet} 
              setShowInfo={props.setShowInfo} 
              showInfo={props.showInfo}
              showCircle={isTwentyOnePlusThreeShown}
            />
            {!sideBet.state &&
              <BetStack
                stackType="LuckyLucky"
                currentBet={currentSideBet}
                chipTextures={chipTextures}
                envMap={envMap}
                showSideBetChips={showSideBetChips}
                position={[-200, 1, 100 + 75]}
                sideBet={sideBet}
              />
            }
            {sideBet.state &&
              <a.group position={sideBetPos}>
                <BetStack 
                  stackType="LuckyLucky" 
                  currentBet={currentSideBet} 
                  chipTextures={chipTextures} 
                  envMap={envMap} 
                  showSideBetChips={showSideBetChips}
                  position={[-200, 1, 100 + 75]} 
                  sideBet={sideBet}
                />
              </a.group>
            }
            
            <BetRing 
              stackType="LuckyLucky" 
              onAddChip={onAddChip} 
              onClearChips={onClearChips} 
              selectedChip={selectedChip}
              currentBet={currentSideBet} 
              chipTextures={chipTextures} 
              envMap={envMap}
              gameState={props.gameState} 
              position={[-200, 1, 100 + 75]} 
              sideBet={sideBet} 
              setShowInfo={props.setShowInfo} 
              showInfo={props.showInfo}
              showCircle={isLuckyLuckyRingShown}
            />
            {props.loadFinished && sideBet.state === 'win' && (
              <a.group position={splitStackPos}>
                <BetStack
                  stackType="default"
                  onAddChip={onAddChip}
                  onClearChips={onClearChips}
                  selectedChip={selectedChip}
                  isSplit={props.isSplit}
                  currentBet={winSidebetChips}
                  chipTextures={chipTextures}
                  envMap={envMap}
                  gameState={props.gameState}
                  position={[-150, 1, 112.5]}
                  sideBet={sideBet}
                />
              </a.group>
            )}
            </>}
          </Suspense>
        </group>
      </Canvas>
    </div>
  );
};

export default Graphics;