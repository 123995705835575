import IconService from 'icon-sdk-js';
import { iconService } from '../utils';
import { dispatchActiveGame } from '../events/child';
import store from "../redux/store";
import { setActiveGames, setBetLimits } from '../redux/action';

export const askForLogin = () => {
  window.parent.dispatchEvent(
    new CustomEvent('LOGIN_REQUEST', {
      detail: {
        // type: 'LOGIN_REQUEST',
      },
    }),
  );
};

export const updateLeaderboard = () => {
  window.parent.dispatchEvent(
    new CustomEvent('UPDATE_LEADERBOARD')
  );
};

export const createTransaction = ({ walletAddress, params, value }) => {
  const { IconConverter, IconBuilder, IconAmount } = IconService;
  const txnBuilder = new IconBuilder.CallTransactionBuilder();
  const txnData = txnBuilder
    .from(walletAddress)
    .to(process.env.REACT_APP_CONTRACT_ADDRESS)
    .nid(IconConverter.toBigNumber(process.env.REACT_APP_NID))
    .timestamp(new Date().getTime() * 1000)
    .stepLimit(IconConverter.toBigNumber(100000000))
    .version(IconConverter.toBigNumber(3))
    .method('take_action')
    .params(params)
    .value(IconAmount.of(value || 0, IconAmount.Unit.ICX).toLoop())
    .build();

  const txnPayload = {
    jsonrpc: '2.0',
    method: 'icx_sendTransaction',
    params: IconConverter.toRawTransaction(txnData),
    id: 50889,
  };
  console.log(txnPayload);
  window.parent.dispatchEvent(
    new CustomEvent('ICONEX_RELAY_REQUEST', {
      detail: {
        type: 'REQUEST_JSON-RPC',
        payload: txnPayload,
      },
    }),
  );
};

const getCallBuilderValue = async (method, walletAddress, txHash) => {
  const { CallBuilder } = IconService.IconBuilder;
  const callObj = new CallBuilder()
    .from(walletAddress)
    .to(process.env.REACT_APP_CONTRACT_ADDRESS)
    .method(method)
    .params({ _tx_hash: txHash})
    .build();
  try {
    const callObjValue = await iconService.call(callObj).execute();
    return callObjValue;
  } catch (err) {
    return null;
  }
}

export const getActiveGames = async (walletAddress) => {
  const { CallBuilder } = IconService.IconBuilder;
  
  const callObj = new CallBuilder()
    .from(walletAddress)
    .to(process.env.REACT_APP_CONTRACT_ADDRESS)
    .method('get_games')
    .params(
      {
        _walletAddress: walletAddress
      }
    )
    .build();
    
  const activeGames = await iconService.call(callObj).execute();
  const activeGamesArray = activeGames ? activeGames.match(/.{1,64}/g) : [];
  if (activeGamesArray.length) {
    store.dispatch(setActiveGames(activeGamesArray.reverse()));
  }
}

export const resumeGame = async (walletAddress, txHash) => {
  const { IconAmount } = IconService;
  const amount = await getCallBuilderValue('get_bet_amount', walletAddress, txHash);
  const gameState = await getCallBuilderValue('get_game_state', walletAddress, txHash);
  const variables = await getCallBuilderValue('get_game_variables', walletAddress, txHash);

  const childHash = await getCallBuilderValue('get_child', walletAddress, txHash);
  const childVariables = await getCallBuilderValue('get_game_variables', walletAddress, childHash);
  const childGameState = await getCallBuilderValue('get_game_state', walletAddress, childHash);
  const value = JSON.parse(amount).mainBet;
  const totalBets = IconAmount.fromLoop(value);

  dispatchActiveGame({
    totalBets, variables, gameState, txHash: txHash, childHash, childVariables, childGameState
  });
}

export const getBetLimits = async (walletAddress) => {
  const { CallBuilder } = IconService.IconBuilder;
  const { IconAmount } = IconService;
  const callObj = new CallBuilder()
    .from(walletAddress)
    .to(process.env.REACT_APP_CONTRACT_ADDRESS)
    .method('get_bet_limits')
    .build();

  const result = await iconService.call(callObj).execute();
  const { minBet, maxBet, minSideBet, maxSideBet } = result && JSON.parse(result);
  const betLimitIcx = {
    minBet: IconAmount.fromLoop(minBet),
    maxBet: IconAmount.fromLoop(maxBet),
    minSideBet: IconAmount.fromLoop(minSideBet),
    maxSideBet: IconAmount.fromLoop(maxSideBet),
  }
  store.dispatch(setBetLimits(betLimitIcx));
}