import { LOCALES } from '../../constants';

export default {
  [LOCALES.CHINESE]: {
    "app.container.info.maxBet": "Max Bet",
    "app.container.info.maxSideBet": "Max Sidebet",
    "app.container.info.balance": "Balance",
    
    "app.container.label.placeBet": "Place Bet",
    "app.container.label.activeGames": "Active Games",
    "app.container.label.luckyLucky": "Lucky Lucky",

    "app.container.action.deal": "Deal",
    "app.container.action.insurance": "Insurance",
    "app.container.action.hit": "Hit",
    "app.container.action.double": "Double",
    "app.container.action.stand": "Stand",
    "app.container.action.split": "Split",
    "app.container.action.bet": "Bet",
    "app.container.action.rebet": "Rebet",

    "app.container.msg.youWon": "YOU WON",
    "app.container.msg.noWin": "NO WIN",
    "app.container.msg.noSideBetWin": "NO SIDEBET WIN",
    "app.container.msg.win": "WIN",
    "app.container.msg.push": "PUSH",
    "app.container.msg.insuranceWinPush": "INSURANCE WIN & GAME PUSH",
    "app.container.msg.insuranceWinLose": "INSURANCE WIN & GAME NO WIN",
    "app.container.msg.loseInsuranceWin": "INSURANCE WIN & GAME NO WIN",

    "app.container.popup.help": 'Help',
    "app.container.popup.luckylucky": "LuckyLucky",
    "app.container.popup.luckylucky.content": "In this side bet, a player can win in 8 different combinations of the three cards described below:- <br> \
      <ul> \
      <li><b>Suited 777:</b> Payout of 200 to 1, if all the cards have the same suit and a rank of 7.</li> \
      <li><b>Suited 678:</b> Payout of 100 to 1 if the cards have the same suit and ranks of 6,7 and 8 each.</li> \
      <li><b>777:</b> Payout of 50 to 1 if all the cards have a rank of 7.</li> \
      <li><b>678:</b> Payout of 30 to 1 if the cards have ranks of 6,7 and 8 each.</li> \
      <li><b>Suited 21:</b> Payout of 10 to 1 if all the cards have the same suit and a sum total of 21.</li> \
      <li><b>21 Total:</b> Payout of 3 to 1 if the cards have a sum total of 21.</li> \
      <li><b>20 Total:</b> Payout of 2 to 1 if the cards have a sum total of 20.</li> \
      <li><b>19 Total:</b> Payout of 1 to 1 if the cards have a sum total of 19.</li> \
      </ul> \
      <table> \
        <thead> \
          <tr> \
            <th></th> \
            <th>Pays</th> \
            <th>Combinations</th> \
            <th>Probability</th> \
          </tr> \
        </thead> \
        <tbody> \
          <tr> \
            <th>Suited 7-7-7</th> \
            <td>200</td> \
            <td>40</td> \
            <td>0.000014</td> \
          </tr> \
          <tr> \
            <th>Suited 6-7-8</th> \
            <td>100</td> \
            <td>500</td> \
            <td>0.000173</td> \
          </tr> \
          <tr> \
            <th>Unsuited 7-7-7</th> \
            <td>50</td> \
            <td>1,100</td> \
            <td>0.00038</td> \
          </tr> \
          <tr> \
            <th>Unsuited 6-7-8</th> \
            <td>30</td> \
            <td>7,500</td> \
            <td>0.000173</td> \
          </tr> \
          <tr> \
            <th>Suited a total of 21</th> \
            <td>10</td> \
            <td>15,300</td> \
            <td>0.005284</td> \
          </tr> \
          <tr> \
            <th>Unsuited total of 21</th> \
            <td>3</td> \
            <td>234,900</td> \
            <td>0.081123</td> \
          </tr> \
          <tr> \
            <th>Total of 20</th> \
            <td>2</td> \
            <td>218,200</td> \
            <td>0.075355</td> \
          </tr> \
          <tr> \
            <th>Total of 19</th> \
            <td>1</td> \
            <td>210,600</td> \
            <td>0.072731</td> \
          </tr> \
        </tbody> \
      </table> \
      <p>Note, if more than one combination is matched, the one with the larger payout only will be provided.</p> \
    ",

    "app.container.popup.twentyOnePlusThree": "21 + 3",
    "app.container.popup.twentyOnePlusThree.content": "In this side bet, a player can win in 5 different combinations of the three cards described below: \
      <ul> \
        <li><b>Suited three of a kind:</b> Payout of 100 to 1 if all the cards have the same suit and rank.</li> \
        <li><b>Three of a kind:</b> Payout of 33 to 1 if all the cards have the same rank.</li> \
        <li><b>Straight flush:</b> Payout of 35 to 1 if all the cards have the same suit and their ranks are successive.</li> \
        <li><b>Straight:</b> Payout of 10 to 1 if the ranks of the cards are successive.</li> \
        <li><b>Flush:</b> Payout of 5 to 1 if the cards have the same suit.</li> \
      </ul> \
      <table> \
        <thead> \
          <tr> \
            <th></th> \
            <th>Pays</th> \
            <th>Combinations</th> \
            <th>Probability</th> \
          </tr> \
        </thead> \
        <tbody> \
          <tr> \
            <th>Suited three of a kind</th> \
            <td>100</td> \
            <td>1,040</td> \
            <td>0.000207</td> \
          </tr> \
          <tr> \
            <th>Three of a kind</th> \
            <td>33</td> \
            <td>25,272</td> \
            <td>0.005041</td> \
          </tr> \
          <tr> \
            <th>Straight flush</th> \
            <td>35</td> \
            <td>10,368</td> \
            <td>0.002068</td> \
          </tr> \
          <tr> \
            <th>Straight</th> \
            <td>10</td> \
            <td>155,520</td> \
            <td>0.031021</td> \
          </tr> \
          <tr> \
            <th>Flush</th> \
            <td>5</td> \
            <td>292,896</td> \
            <td>0.058424</td> \
          </tr> \
        </tbody> \
      </table> \
      <p>Note if more than one combination is matched, the one with the larger payout only will be provided.</p> \
    ",

    "app.container.popup.faq": "FAQ",
    "app.container.popup.faq.content": " \
      <div> \
        <ul><li><em>What are the rules for Blackjack?</em></li></ul> \
        <p>Blackjack uses fairly standard blackjack rules: \
          <br> - 6-deck shoe \
          <br> - Blackjack pays 6 to 5 \
          <br> - Insurance offered with 2 to 1 payout if Dealer shows an Ace \
          <br> - Player can double on first two cards if they total 9, 10, or 11 \
          <br> - Dealer hits on soft 17 \
          <br> - No drawing to split aces (only draws one card to each new hand) \
          <br> - No dealer peek for blackjack - Sorry! Not possible to do that with a fully transparent blockchain dApp.  \
          <br> - Split to two hands \
          <br>- No double after split \
        </p> \
        <ul><li><em>Can I play more than one hand at a time?</em></li></ul> \
        <p> \
          The game only supports one player at the table against the dealer, but you can open multiple games. Three games seems to be a good number, and you can have up to five. \
        </p> \
        <ul><li><em>I see a list of ‘Active Games’ What is that?</em></li></ul> \
        <p> \
          If you can’t complete a game for any reason it will appear under Active Games and you can resume play by clicking on it. It will open in a new browser tab. \
        </p> \
        <ul> \
          <li><em>I don’t know much about Blackjack. How can I start?</em></li> \
        </ul> \
        <p> \
          One great place to start learning about Blackjack, and many other casino games is &nbsp; \
          <a href='https://wizardofodds.com/games/blackjack/' target='_blank' rel='noopener noreferrer'> Wizard of Odds. </a> \
        </p> \
      </div> \
    "
  }
}