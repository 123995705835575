import * as THREE from 'three';
import React, { useMemo } from 'react';
import { a } from 'react-spring/three';
import { useThree } from 'react-three-fiber';

function Card({ card, backTexture, geometry, matSettings, position, rotation, cardImgArray = null, ...props }) {
  const { gl } = useThree()
  
  const url = `./graphics/cards/${card.value}${card.type}.png`
  const frontTexture = useMemo(() => {
    if (cardImgArray && card.value && card.type) return cardImgArray[`${card.value}${card.type}`]
    return new THREE.TextureLoader().load(url)
  }, [url])

  if (frontTexture) {
    frontTexture.flipY = false
    frontTexture.anisotropy = gl.capabilities.getMaxAnisotropy()
    frontTexture.minFilter = THREE.NearestFilter
  }

  return (
    <a.group
      position={position.interpolate((x, y, z) => [x, y, z])}
      rotation={rotation.interpolate((x, y, z) => [x, y, z])}
      scale={[1, 1.5, 1]}
    >
      <group rotation={[-Math.PI / 2, Math.PI, 0]}>
        <mesh name="cardFront" geometry={geometry[0]}>
          <meshStandardMaterial attach="material" {...matSettings.card}>
            {frontTexture && <primitive attach="map" object={frontTexture} />}
          </meshStandardMaterial >
        </mesh>
        <mesh name="cardBack" geometry={geometry[1]}>
          <meshStandardMaterial attach="material" {...matSettings.card}>
            <primitive attach="map" object={backTexture} />
          </meshStandardMaterial>
        </mesh>
        <mesh name="cardSide" geometry={geometry[2]}>
          <meshStandardMaterial attach="material" {...matSettings.gold} />
        </mesh>
      </group>
    </a.group>
  );
}

export default Card;
