import store from "../redux/store";
import * as THREE from "three";
import queryString from "query-string";

import {
  saveWalletAddress,
  updateCurrentGame,
  setCanSplit,
  splitGame,
  setCanInsurance,
  setTransactionInProgress,
  swapCurrentGame,
  updateSideBet,
  updateChildGame,
  eventListenersSet,
  updateParentGame,
  updateParentChildMessage,
  setIsInsurance,
  setInsuranceAmount,
  setInsuranceResult,
  setActiveGames,
  updatePayout,
  setTransactionCancelled,
  setSideBetRingBlink,
  setLocale,
} from "../redux/action";
import { getGameStateMessage, iconService, getAmountFromHex } from "../utils";
import { updateLeaderboard } from "../api";

const timeout = instance => {
  const seconds = instance === 1 ? 2000 : 1000;
  return new Promise(resolve => setTimeout(resolve, seconds));
};

const getCardsFromString = cardString => {
  const cardsArray = cardString.match(/.{1,2}/g).map(card => {
    return {
      type: card[0],
      value: card[1] === "T" ? 10 : card[1],
      id: THREE.Math.generateUUID()
    };
  });
  return cardsArray;
};

export const dispatchTotalBet = (betAmount) => {
  const currentGame = store.getState().blackjackReducer.parentGame;
  store.dispatch(
    updateCurrentGame({
      ...currentGame,
      totalBets: betAmount
    }),
  );
}

const resetParentLocation = () => {
  const {pathname, search} = window.parent.location;
  const parsedQuery = queryString.parse(search);
  if (parsedQuery.hash) {
    const activeGames = store.getState().blackjackReducer.activeGames;
    store.dispatch(setActiveGames(activeGames.filter( game => game !== parsedQuery.hash)));
    window.parent.history.replaceState(null, null, pathname);
  }
}

export const dispatchActiveGame = (activeGame) => {
  const {totalBets, variables, gameState, txHash, childHash, childVariables, childGameState} = activeGame;
  const currentState = store.getState().blackjackReducer;
  const {parentGame, childGame} = currentState;
  const gameCards = variables && JSON.parse(variables);
  const transactionHash = `0x${txHash}`;
  switch(gameState) {
    case 'INITIAL': {
      store.dispatch(setTransactionInProgress(true));
      getResult(transactionHash, 1);
      store.dispatch(updateParentGame({
        ...parentGame,
        totalBets
      }));
      return;
    }
    case 'HIT': {
      store.dispatch(updateParentGame({
        ...parentGame,
        transactionHash,
        totalBets,
        gameState: gameState.toLowerCase(),
        dealerCards: getCardsFromString(gameCards.dealer),
        playerCards: getCardsFromString(gameCards.player)
      }));
      return;
    }
    case 'SPLITHIT':
    case 'SPLIT': {
      const childTransactionHash = `0x${childHash}`;
      const childGameCards = childVariables && JSON.parse(childVariables);
      const updatedChildGame = {
        ...childGame,
        transactionHash: childTransactionHash,
        totalBets,
        gameState: childGameState.toLowerCase(),
        standClicked: ['SPLITLOSE', 'SPLITSTAND'].includes(childGameState) ? true : false,
        playerCards: getCardsFromString(childGameCards.player),
        dealerCards: getCardsFromString(childGameCards.dealer),
      };
      const updatedParentGame = {
        ...parentGame,
        transactionHash,
        totalBets,
        gameState: gameState.toLowerCase(),
        dealerCards: getCardsFromString(gameCards.dealer),
        playerCards: getCardsFromString(gameCards.player)
      };
      store.dispatch(splitGame({
        ...currentState,
        parentGame: updatedParentGame,
        childGame: updatedChildGame,
        currentGameType: ['SPLITLOSE', 'SPLITSTAND'].includes(childGameState) ? 'parent' : 'child',
        isSplit: true,
        canSplit: false
      }));
      return;
    }
    case "INSURANCEHIT":
    case "INSURANCE": {
      store.dispatch(updateParentGame({
        ...parentGame,
        transactionHash,
        totalBets,
        gameState: gameState.toLowerCase(),
        playerCards: getCardsFromString(gameCards.player),
        dealerCards: getCardsFromString(gameCards.dealer)
      }));
      store.dispatch(setCanInsurance(false));
      store.dispatch(setIsInsurance(true));
      store.dispatch(setInsuranceAmount(totalBets/2));
      return;
    }
  }
}

const dispatchAction = (txResult, txHash) => {
  if (!txResult.status) {
    throw new Error(txResult.failure.message);
  }
  const eventLogs = txResult.eventLogs;
  const gameState = eventLogs.find(event => event.indexed[0] === "State(str)")
    .indexed[1];
  const currentState = store.getState().blackjackReducer;
  const currentGame =
    currentState.currentGameType === "parent"
      ? currentState.parentGame
      : currentState.childGame;
  
  if (eventLogs.length >= 6 && eventLogs.filter(event => event.indexed[0] === "CardDrawn(str)").length === 2) {
    // This will be the split ace case
    const gameStates = eventLogs.filter(event => event.indexed[0] === "State(str)");
    const cardsString = eventLogs.filter(event => event.indexed[0] === "CardDrawn(str)");
    const payOutEvents = eventLogs.filter(
      event => event.indexed[0] === "Payout(int)"
    );
    const childCards = [currentGame.playerCards[1], ...getCardsFromString(cardsString[1].indexed[1])];
    const parentCards = [currentGame.playerCards[0], ...getCardsFromString(cardsString[0].indexed[1])];
    const dealerCardsString = eventLogs.find(event => event.indexed[0] === "PlayerDealerCards(str,str)").indexed[2];
    const dealerCards = [currentGame.dealerCards[0], ...getCardsFromString(dealerCardsString.slice(2))];
    
    const childGame = {
      ...currentState.childGame,
      playerCards: childCards,
      dealerCards,
      standClicked: true,
      gameState: gameStates[0].indexed[1],
      result: gameStates[0].indexed[1],
      message: getGameStateMessage(gameStates[0].indexed[1]),
    };

    const parentGame = {
      ...currentState.parentGame,
      playerCards: parentCards,
      dealerCards,
      standClicked: true,
      result: gameStates[1].indexed[1],
      message: getGameStateMessage(gameStates[1].indexed[1]),
      gameState: 'end'
    };
    store.dispatch(splitGame({
      ...currentState,
      parentGame,
      childGame,
      isSplit: true,
      canSplit: false
    }));
    if (payOutEvents.length) {
      const payOutHex = payOutEvents[0].indexed[1];
      store.dispatch(updatePayout(
        {
          amount: getAmountFromHex(payOutHex),
          show: true
        }
      ));
    }
    updateLeaderboard();
    return;
  }
  if (gameState === "SPLIT") {
    const cards = eventLogs.find(
      event => event.indexed[0] === "CardDrawn(str)"
    );
    const parentCards = [currentGame.playerCards[0]];
    const childCards = [
      currentGame.playerCards[1],
      ...getCardsFromString(cards.indexed[1])
    ];
    const parentGame = {
      ...currentGame,
      playerCards: parentCards,
      gameState: gameState.toLowerCase()
    };
    const childGame = {
      ...currentGame,
      playerCards: childCards,
      gameState: gameState.toLowerCase(),
      transactionHash: txHash
    };
    return store.dispatch(
      splitGame({
        ...currentState,
        parentGame,
        childGame,
        currentGameType: "child",
        isSplit: true,
        canSplit: false
      })
    );
  }
  if (["SPLITLOSE", "SPLITSTAND"].includes(gameState) && currentState.currentGameType === "child") {
    const gameStates = eventLogs.filter(event => event.indexed[0] === "CardDrawn(str)");
    if (gameStates.length === 1) {
      store.dispatch(updateChildGame({
        ...currentState.childGame,
        standClicked: true,
      }));
      store.dispatch(swapCurrentGame());
      store.dispatch(
        updateParentGame({
          ...currentState.parentGame,
          playerCards: [...currentState.parentGame.playerCards, ...getCardsFromString(gameStates[0].indexed[1])]
        })
      );
      return;
    }
    store.dispatch(updateChildGame({
      ...currentGame,
      standClicked: true,
      playerCards: [...currentGame.playerCards, ...getCardsFromString(gameStates[0].indexed[1])]
    }));
    store.dispatch(swapCurrentGame());
    store.dispatch(updateParentGame({
      ...currentState.parentGame,
      playerCards: [...currentState.parentGame.playerCards, ...getCardsFromString(gameStates[1].indexed[1])]
    }));
    return;
  }
  if (gameState === "INSURANCE") {
    return store.dispatch(
      updateCurrentGame({
        ...currentGame,
        gameState: gameState.toLowerCase()
      })
    );
  }

  switch (gameState) {
    case "INITIAL": {
      const eventCards = eventLogs.find(
        event => event.indexed[0] === "PlayerDealerCards(str,str)"
      ).indexed;
      const playerCards = getCardsFromString(eventCards[1]);
      const dealerCards = getCardsFromString(eventCards[2]);
      const sidebetWin = eventLogs.find(
        event => event.indexed[0] === "SideBetWin(str)"
        );
      if (sidebetWin) {
        const payOutEvent = eventLogs.find(
          event => event.indexed[0] === "Payout(int)"
        );
        const payOutHex = payOutEvent.indexed[1];
        store.dispatch(
          updateSideBet({
            amount: currentState.sideBet.amount,
            type: currentState.sideBet.type,
            state: "win",
            show: true,
            payout: getAmountFromHex(payOutHex),
            message: "app.container.msg.youWon"
          })
        );
      } else if (currentState.sideBet.amount) {
        store.dispatch(
          updateSideBet({
            amount: currentState.sideBet.amount,
            type: currentState.sideBet.type,
            state: "lose",
            show: true,
            message: "app.container.msg.noSideBetWin",
            payout: 0
          })
        );
      }

      if (playerCards[0].value === playerCards[1].value) {
        store.dispatch(setCanSplit(true));
      }
      if (dealerCards[0].value === "A") {
        store.dispatch(setCanInsurance(true));
      }
      store.dispatch(
        updateCurrentGame({
          ...currentGame,
          message: "",
          gameState: gameState.toLowerCase(),
          transactionHash: txHash,
          playerCards,
          dealerCards
        })
      );
      return;
    }
    case "SPLITHIT":
      console.log("Split HIT");
      const drawnCards = eventLogs.find(
        event => event.indexed[0] === "CardDrawn(str)"
      );
      const playerCards = [
        ...currentGame.playerCards,
        ...getCardsFromString(drawnCards.indexed[1])
      ];
      store.dispatch(
        updateCurrentGame({
          ...currentGame,
          gameState: gameState.toLowerCase(),
          playerCards
        })
      );
      return;
    case "INSURANCEHIT":
    case "HIT": {
      console.log("HIT");
      const playerCards = [
        ...currentGame.playerCards,
        ...getCardsFromString(eventLogs[0].indexed[1])
      ];
      store.dispatch(
        updateCurrentGame({
          ...currentGame,
          gameState: gameState.toLowerCase(),
          playerCards
        })
      );
      return;
    }
    case "SPLITSTAND":
    case "SPLITLOSE":
    case "SPLITPUSH":
    case "SPLITWIN":
    case "WIN":
    case "LOSE":
    case "PUSH":
    case "INSURANCEWIN": {
      const eventCards = eventLogs.find(
        event => event.indexed[0] === "PlayerDealerCards(str,str)"
      ).indexed;
      if (currentState.currentGameType === "child") {
        // This case will probably never be called as we have handled it above, will delete after confirmation.
        const eventPlayerCards = eventCards[1];
        const childPlayerCards =
          eventPlayerCards.length / 2 > currentGame.playerCards.length
            ? [
                ...currentGame.playerCards,
                ...getCardsFromString(eventLogs[2].indexed[1])
              ]
            : currentGame.playerCards;
        store.dispatch(
          updateCurrentGame({
            ...currentGame,
            gameState: gameState.toLowerCase(),
            playerCards: childPlayerCards,
            message: getGameStateMessage(gameState)
          })
        );
        store.dispatch(swapCurrentGame());
      } else {
        const eventDealerCards = eventCards[2];
        const eventPlayerCards = eventCards[1];
        const currentPlayerCards = currentGame.playerCards;
        const currentDealerCards = currentGame.dealerCards;
        const dealerCards =
          eventDealerCards.length / 2 > currentDealerCards.length
            ? [
                ...currentGame.dealerCards,
                ...getCardsFromString(
                  eventDealerCards.slice(
                    -2 *
                      (eventDealerCards.length / 2 - currentDealerCards.length)
                  )
                )
              ]
            : currentDealerCards;
        const playerCards =
          eventPlayerCards.length / 2 > currentPlayerCards.length
            ? [
                ...currentGame.playerCards,
                ...getCardsFromString(
                  eventPlayerCards.slice(
                    -2 *
                      (eventPlayerCards.length / 2 - currentPlayerCards.length)
                  )
                )
              ]
            : currentPlayerCards;
        store.dispatch(
          updateCurrentGame({
            ...currentGame,
            gameState: "end",
            standClicked: true,
            dealerCards,
            playerCards,
            message: getGameStateMessage(gameState),
            result: gameState,
          })
        );
        updateLeaderboard();
        const payOutEvents = eventLogs.filter(
          event => event.indexed[0] === "Payout(int)"
        );
        if (payOutEvents.length === 2) {
          // Case when user wins the sidebet and gets blackjack as well
          const gamePayoutHex = payOutEvents[1].indexed[1];
          store.dispatch(updatePayout(
            {
              amount: getAmountFromHex(gamePayoutHex),
              show: true
            }
          ));

          const sideBetPayoutHex = payOutEvents[0].indexed[1];
          store.dispatch(updateSideBet(
            {
              amount: currentState.sideBet.amount,
              type: currentState.sideBet.type,
              state: "win",
              show: true,
              payout: getAmountFromHex(sideBetPayoutHex),
              message: "app.container.msg.youWon",
            }
          ));
        } else if (payOutEvents.length === 1) {
          const payOutHex = payOutEvents[0].indexed[1];
          store.dispatch(updatePayout(
            {
              amount: getAmountFromHex(payOutHex),
              show: true
            }
          ));
          // if (currentState.sideBet.amount && !currentState.sideBet.show) {
          //   store.dispatch(
          //     updateSideBet({
          //       amount: currentState.sideBet.amount,
          //       type: currentState.sideBet.type,
          //       state: "lose",
          //       show: true,
          //       message: "NO SIDEBET WIN",
          //       payout: 0
          //     })
          //   );
          // }
        }
        if (currentState.isSplit) {
          const gameStates = eventLogs.filter(
            event => event.indexed[0] === "State(str)"
          );
          store.dispatch(updateChildGame({
            ...currentState.childGame,
            result: gameStates[1].indexed[1],
          }));
          store.dispatch(
            updateParentChildMessage({
              parentMessage: getGameStateMessage(gameStates[0].indexed[1]),
              childMessage: getGameStateMessage(gameStates[1].indexed[1])
            })
          );
        } else if (currentState.isInsurance) {
          const gameStates = eventLogs.filter(
            event => event.indexed[0] === "State(str)"
          );
          const message = `${gameStates[0].indexed[1]}${gameStates[1] ? gameStates[1].indexed[1] : ''}`;
          if (gameStates.length < 2) {
            // Insurance lose case
            store.dispatch(setInsuranceResult("LOSE"));
          } else {
            store.dispatch(setInsuranceResult("WIN"));
          }
          store.dispatch(
            updateParentChildMessage({
              parentMessage: getGameStateMessage(message),
              childMessage: ""
            })
          );
        };
        resetParentLocation();
      }
      return;
    }

    default:
      return;
  }
};

export const getResult = async (txHash, instance) => {
  try {
    await timeout(instance);
    const txObject = await iconService.getTransactionResult(txHash).execute();
    store.dispatch(setTransactionInProgress(false));
    dispatchAction(txObject, txHash);
  } catch (err) {
    if (instance === 5) {
      console.log(err);
      store.dispatch(setTransactionInProgress(false));
      return false;
    }
    instance = instance + 1;
    console.log(`Retrying...., Atempt ${instance - 1}`);
    getResult(txHash, instance);
  }
};

const setChildEventListener = () => {
  //add listener for language change
  window.parent.addEventListener(
    'LOCALE_CHANGED',
    event => {
      if(event.detail && event.detail.locale) {
        store.dispatch(setLocale(event.detail.locale));
        console.log("Locale Changed to", event.detail.locale);
      }
    }
  )
  
  window.parent.addEventListener(
    "ICONEX_RELAY_RESPONSE",
    event => {
      var type = event.detail.type;
      var payload = event.detail.payload;
      switch (type) {
        case "RESPONSE_ADDRESS":
          store.dispatch(saveWalletAddress(payload));
          break;
        case "RESPONSE_JSON-RPC":
          getResult(payload.result, 1);
          break;
        case "CANCEL_JSON-RPC":
          store.dispatch(setTransactionInProgress(false));
          store.dispatch(setTransactionCancelled(true));
          break;
        default:
      }
    },
    false
  );
  store.dispatch(eventListenersSet());
};

export default setChildEventListener;
