import React, { useState } from 'react';
import translate from '../../translations/Provider/translate';
import {injectIntl} from 'react-intl';

const subHeaders = [
  {
    title: 'app.container.popup.luckylucky',
    content: 'app.container.popup.luckylucky.content'
  },
  {
    title: 'app.container.popup.twentyOnePlusThree',
    content: 'app.container.popup.twentyOnePlusThree.content'
  },
  {
    title: 'app.container.popup.faq',
    content: 'app.container.popup.faq.content'
  },
];



function HelpOverlay ({ closeModal, ...props }) {

  const [selectedSubHeader, setSelectedSubHeader] = useState(subHeaders[0].title);

  const handleClick = (e) => {
    if(e.target.className == 'help-overlay' || e.target.className == 'close-icon')
      closeModal();
  }

  const getContent = () => {
    let returnData;
    for(let subHeader of subHeaders) {
      if(subHeader.title == selectedSubHeader) {
        returnData = subHeader.content;
        break;
      }
    }

    return { 
      __html : props.intl.formatMessage({id: returnData})
    }
  }

  return (
    <div className='help-overlay' onClick={handleClick}>
      <div className='overlay-content-wrapper'>
        <div className='overlay-content'>
          <div className='close-icon'>x</div>

          <div className='header'>
            <h1>{translate('app.container.popup.help')}</h1>
          </div>

          <div className='sub-header'>
            {subHeaders.map((subHeader, index) => (
              <div key={index} className='sub-head' onClick={() => setSelectedSubHeader(subHeader.title)}>
                {props.intl.formatMessage({id: subHeader.title})}
                {selectedSubHeader === subHeader.title &&
                   <div className='border' />
                }
              </div>
            ))}
          </div>

          <p className='overlay-body' dangerouslySetInnerHTML={getContent()}>
          </p>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(HelpOverlay);