import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import AppRouter from './AppRouter';
import { BrowserRouter } from 'react-router-dom';
import Blackjack from './component';

ReactDOM.render(
  <Provider store={store}>
    <Blackjack />
  </Provider>,
  document.getElementById('root'),
);

// Use this render for development instead of above

// ReactDOM.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <AppRouter />
//     </BrowserRouter>
//   </Provider>,
//   document.getElementById('root'),
// );
