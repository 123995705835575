import React, { useRef, useState, useEffect } from 'react'
import { useSpring, a } from 'react-spring/three'

import useModel from '../helpers/useModel'
import Chip from './Chip'

import renderFrame from '../helpers/renderFrame'

function ChipStack({ index, children, selected, setSelected, hover, ...props }) {
  const isSelected = selected === index ? 265 : 290
  const { pos } = useSpring({ pos: isSelected, onFrame: () => renderFrame() })

  const handleClick = () => {
    if (selected !== index) setSelected(index)
    else setSelected(null)
  }

  return (
    <a.group
      rotation={[-Math.PI / 2, 0, 0]}
      position={pos.interpolate(pos => [(index - 4) * 50, 0, pos])}
      onClick={() => handleClick()}
      onPointerOver={() => hover(true)}
      onPointerOut={() => hover(false)}>
      {children}
    </a.group>
  )
}

function Chips({ envMap, ...props }) {
  const group = useRef()
  const [geometries] = useModel('./graphics/chips/chip.glb')

  const matSettings = {
    chip: {
      roughness: 0.6,
      metalness: 0.15,
      envMap: envMap,
      envMapIntensity: 3
    }
  }

  const chipValues = [0.2, 0.5, 1, 5, 10, 25, 100]
  const chipColors = ['#E99068', '#009fe3', '#E1DACF', '#B92C34', '#425BBB', '#1FA959', '#333431']

  const [hovered, hover] = useState(false)

  useEffect(() => {
    document.body.style.cursor = hovered
      ? 'pointer'
      : "auto"
  }, [hovered])

  return (
    <group ref={group}>
      {chipValues.map((chip, index) => {
        if (chip === 0.2 || chip === 0.5) {
          return null
        } else {
          return <ChipStack key={index} index={index} selected={props.selectedChip} setSelected={props.setSelectedChip} hover={hover}>
            <Chip texture={props.chipTextures[chipValues.indexOf(chip)]} color={chipColors[index]} geometry={geometries} offset={0} matSettings={matSettings} />
            <Chip texture={props.chipTextures[chipValues.indexOf(chip)]} color={chipColors[index]} geometry={geometries} offset={1} matSettings={matSettings} />
            <Chip texture={props.chipTextures[chipValues.indexOf(chip)]} color={chipColors[index]} geometry={geometries} offset={2} matSettings={matSettings} />
            <Chip texture={props.chipTextures[chipValues.indexOf(chip)]} color={chipColors[index]} geometry={geometries} offset={3} matSettings={matSettings} />
            <Chip texture={props.chipTextures[chipValues.indexOf(chip)]} color={chipColors[index]} geometry={geometries} offset={4} matSettings={matSettings} />
          </ChipStack>
        }
      })}
    </group>
  )
}

export default Chips