import IconService, { IconConverter, HttpProvider, IconAmount } from 'icon-sdk-js';

const provider = new HttpProvider(process.env.REACT_APP_ICON_URL);

export const iconService = new IconService(provider);

export const getCookie = (cname) => {
  let name = cname + '=';
  let ca = window.parent.document.cookie.split(';'); 

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

const message = {
  WIN: "app.container.msg.win",
  LOSE: "app.container.msg.noWin",
  PUSH: "app.container.msg.push",
  SPLITLOSE: "app.container.msg.noWin",
  SPLITPUSH: "app.container.msg.push",
  SPLITWIN: "app.container.msg.win",
  INSURANCEWINPUSH: "app.container.msg.insuranceWinPush",
  INSURANCEWINLOSE: "app.container.msg.insuranceWinLose",
  LOSEINSURANCEWIN: "app.container.msg.loseInsuranceWin"
};

export const getGameStateMessage = (state) => message[state];

export const getBalance = async (walletAddress) => {
  let balance = await iconService
    .getBalance(getCookie('wallet_address'))
    .execute();
  balance = parseFloat(
    IconConverter.toNumber(balance) / Math.pow(10, 18),
  ).toFixed(2);
  return balance;
};

export const getAmountFromHex = (hex) => {
  const bigNum = IconConverter.toNumber(hex);
  const icx = IconAmount.fromLoop(bigNum);
  return icx;
}

export const roundOfToTwoDecimals = (val) => {
  return Math.round( ( Number(val) + Number.EPSILON ) * 100 ) / 100
}

const isSuited = (cards) => {
  return cards[0].type === cards[1].type && cards[0].type === cards[2].type;
}

const isThreeOfAKind = (cards) => {
  return cards[0].value === cards[1].value && cards[0].value === cards[2].value;
}

const isTotalOfN = (cards, number) => {
  let containsAce = false;
  const totalValue = cards.reduce((acc, card) => {
    if (['T', 'J', 'Q', 'K', 'L'].includes(card.value)) {
      return acc + 10;
    }
    if (card.value === 'A') {
      containsAce = true;
      return acc + 1;
    }
    return acc + Number(card.value);
  }, 0);
  return totalValue === number || containsAce && totalValue + 10 === number;
}

const sortCards = (cards) => {
  const arrayOfCards = ['A', '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K'];
  const formattedCards = cards.map(card => {
    card['rank'] = arrayOfCards.indexOf(card.value);
    return card;
  });
  return formattedCards.sort((a, b) => a.rank - b.rank)
}

const isStraight = (sortedCards) => {
  if (sortedCards[0].value === 'A' && sortedCards[1].value === 'Q' && sortedCards[2].value === 'K') {
    return true;
  }
  return sortedCards[0].rank + 1 === sortedCards[1].rank && sortedCards[1].rank + 1 === sortedCards[2].rank;
}

const checkLuckyLuckyWin = (cards) => {
  const sortedCards = sortCards(cards);
  if (cards[0].value === '7' && isThreeOfAKind(cards)) {
    // Suited and unsuited 7-7-7
    return true;
  }
  if (sortedCards[0].value === '6' && isStraight(sortedCards)) {
    // Suited and unsuited 6-7-8
    return true;
  }
  if (isTotalOfN(cards, 21) || isTotalOfN(cards, 20) || isTotalOfN(cards, 19)) {
    // Total or 21, 20 or 19
    return true;
  }
  return false;
}

const checkTwentyOnePlusThreeWin = (cards) => {
  if (isThreeOfAKind(cards)) {
    // Suited and unsuited three of a kind
    return true;
  }
  if (isStraight(cards) || isSuited(cards)) {
    // Straight or Flush
    return true;
  }
  return false
}

export const checkSidebetWinningHand = (playerCards, dealerCards) => {
  const allCards = [...playerCards, ...dealerCards];
  const luckyLucky = checkLuckyLuckyWin(allCards);
  const twentyOnePlusThree = checkTwentyOnePlusThreeWin(allCards);
  return {
    luckyLucky,
    twentyOnePlusThree,
  }
}
