import * as THREE from 'three'
import React, { useState, useEffect, useMemo } from 'react'
import { useThree } from 'react-three-fiber'
import useModel from '../helpers/useModel'
import Chip from './Chip'

const chipValues = [0.2, 0.5, 1, 5, 10, 25, 100]
const chipColors = ['#E99068', '#009fe3', '#E1DACF', '#B92C34', '#425BBB', '#1FA959', '#333431']

function BetStack({ stackType, currentBet, showSideBetChips = true, envMap, chipTextures, sideBet, position }) {
  const [geometries] = useModel('./graphics/chips/chip.glb')

  const matSettings = {
    chip: {
      roughness: 0.6,
      metalness: 0.15,
      envMap: envMap,
      envMapIntensity: 3
    }
  }

  return (
    <group>
      <group rotation={[-Math.PI / 2, 0, 0]} position={position}>
        {(stackType.includes('default') || sideBet.type === stackType) && showSideBetChips && currentBet.map((chip, index) => {
          return <Chip texture={chipTextures[chip]} key={index} color={chipColors[chip]} geometry={geometries} offset={index} matSettings={matSettings} />
        })}
      </group>
    </group>
  )
}

function BetRing({ stackType, selectedChip, onAddChip, onClearChips, currentBet, gameState, showCircle = true, ...props }) {
  const [geometries] = useModel('./graphics/chips/chip.glb')
  
  const matSettings = {
    chip: {
      roughness: 0.6,
      metalness: 0.15,
      envMap: props.envMap,
      envMapIntensity: 3
    }
  }

  const [hovered, hover] = useState(false)
  const [clearHovered, clearHover] = useState(false)
  const [clearInfoHovered, clearInfoHover] = useState(false)

  const { gl } = useThree()
  const clearTexture = useMemo(() => new THREE.TextureLoader().load('./graphics/clear.png'), [])
  clearTexture.anisotropy = gl.capabilities.getMaxAnisotropy()

  const infoTexture = useMemo(() => new THREE.TextureLoader().load('./graphics/info.png'), [])
  infoTexture.anisotropy = gl.capabilities.getMaxAnisotropy()

  useEffect(() => {
    document.body.style.cursor = selectedChip !== null && hovered && !gameState
      ? 'none'
      : "auto"
  }, [hovered, gameState, selectedChip])

  const [position, setPosition] = useState([0, 0, 0]);
  const handleMove = e => {
    setPosition([e.point.x, currentBet.length * 5, e.point.z * -2])
  }

  const handleShowInfo = () => {
    if (props.showInfo === stackType) props.setShowInfo(null)
    else props.setShowInfo(stackType)
  }
  return (
    <group>
      {selectedChip !== null && hovered && !gameState &&
        <group rotation={[-Math.PI / 2, 0, 0]} position={[...position]}>
          <Chip texture={props.chipTextures[selectedChip]} color={chipColors[selectedChip]} geometry={geometries} offset={0} matSettings={matSettings} />
        </group>
      }
      <group rotation={[-Math.PI / 2, 0, 0]} position={props.position}>
        { showCircle ? <mesh>
          <ringBufferGeometry attach="geometry" args={[25, 27.5, 32]} />
          <meshBasicMaterial attach="material" transparent={true} opacity={selectedChip !== null && hovered && !gameState ? 0.5 : 0.25} />
        </mesh> : null}
        {(!gameState && (stackType.includes('default') || (!props.sideBet.type || props.sideBet.type === stackType))) &&
          <>
            <mesh
              position={[45, 0, 0]}
              onClick={() => onClearChips(stackType)}
              onPointerOver={() => clearHover(true)}
              onPointerOut={() => clearHover(false)}>
              <circleBufferGeometry attach="geometry" args={[10, 32]} />
              <meshBasicMaterial attach="material" transparent={true} opacity={clearHovered && !gameState ? 0.5 : 0.25}>
                <primitive attach="map" object={clearTexture} />
              </meshBasicMaterial>
            </mesh>
            <mesh
              onPointerMove={e => handleMove(e)}
              onClick={() => onAddChip(parseFloat(chipValues[selectedChip]), stackType)}
              onPointerOver={() => hover(true)}
              onPointerOut={() => hover(false)}>
              <circleBufferGeometry attach="geometry" args={[25, 32]} />
              <meshBasicMaterial attach="material" transparent={true} opacity={0} />
            </mesh>
          </>
        }
        {!stackType.includes('default') &&
          <mesh
            position={[-45, 0, 0]}
            onClick={() => handleShowInfo()}
            onPointerOver={() => clearInfoHover(true)}
            onPointerOut={() => clearInfoHover(false)}>
            <circleBufferGeometry attach="geometry" args={[10, 32]} />
            <meshBasicMaterial attach="material" transparent={true} opacity={clearInfoHovered ? 0.5 : 0.25}>
              <primitive attach="map" object={infoTexture} />
            </meshBasicMaterial>
          </mesh>
        }
      </group>
    </group>
  )
}

export { BetStack, BetRing }