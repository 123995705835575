import * as THREE from 'three'
import React, { useMemo } from 'react'
import { useLoader, useThree } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

const TableGeometry = ({ envMap, showInfo, ...props }) => {
  const { gl } = useThree()
  const gltf = useLoader(GLTFLoader, './graphics/table/table.glb')

  const tableTexture = useMemo(() => new THREE.TextureLoader().load('./graphics/table/felt.jpg'), [])
  tableTexture.flipY = false
  tableTexture.wrapS = tableTexture.wrapT = THREE.RepeatWrapping
  tableTexture.repeat.set(2.75, 2.75)
  tableTexture.offset.set(0.15, 0.225)

  const shadowTexture = useMemo(() => new THREE.TextureLoader().load('./graphics/table/shadow.jpg'), [])
  shadowTexture.flipY = false

  const cardBackTexture = useMemo(() => new THREE.TextureLoader().load('./graphics/cardBack_latest.png'), [])
  cardBackTexture.flipY = false

  const textTexture = useMemo(() => new THREE.TextureLoader().load('./graphics/text_new_rules.png'), [])
  const sideBetTexture_1 = useMemo(() => new THREE.TextureLoader().load('./graphics/21+3_text.png'), [])
  const sideBetTexture_2 = useMemo(() => new THREE.TextureLoader().load('./graphics/lucky_text.png'), [])

  tableTexture.anisotropy = shadowTexture.anisotropy = cardBackTexture.anisotropy = gl.capabilities.getMaxAnisotropy()
  textTexture.anisotropy = sideBetTexture_1.anisotropy = sideBetTexture_2.anisotropy = gl.capabilities.getMaxAnisotropy()

  const matSettings = {
    black: {
      color: new THREE.Color(0.125, 0.125, 0.125),
      roughness: 0.35,
      metalness: 0.1,
      envMap: envMap,
      envMapIntensity: 4
    },
    clear: {
      color: new THREE.Color(1, 1, 1),
      roughness: 0.025,
      metalness: 0,
      envMap: envMap,
      envMapIntensity: 20,
      transparent: true,
      transparency: 1
    },
    table: {
      roughness: 0.75,
      metalness: 0,
      envMap: envMap,
      envMapIntensity: 0.5,
      map: tableTexture
    },
    card: {
      roughness: 0.5,
      metalness: 0.2,
      envMap: envMap,
      envMapIntensity: 1,
      map: cardBackTexture
    },
    shadow: {
      color: new THREE.Color(0x000000),
      alphaMap: shadowTexture,
      transparent: true,
      opacity: 0.5
    },
    text: {
      transparent: true,
      map: textTexture,
      opacity: 0.667
    }
  }

  return (
    <group>
      <mesh name="table_text" position={[0, 0.5, 35]} scale={[0.67, 0.67, 0.67]} rotation={[-Math.PI / 2, 0, 0]}>
        <meshBasicMaterial attach="material" {...matSettings.text} />
        <planeGeometry attach="geometry" args={[500, 500]} />
      </mesh>
      {showInfo === '21P3' && <mesh name="sideBet_1_text" position={[190, 0.75, 160]} scale={[0.175, 0.175, 0.175]} rotation={[-Math.PI / 2, 0, 0]}>
        <meshBasicMaterial attach="material" {...matSettings.text} map={sideBetTexture_1} opacity={0.5} />
        <planeGeometry attach="geometry" args={[450 * 1.4, 450 * 1.4]} />
      </mesh>}
      {showInfo === 'LuckyLucky' && <mesh name="sideBet_1_text" position={[190, 0.75, 160]} scale={[0.2125, 0.2125, 0.2125]} rotation={[-Math.PI / 2, 0, 0]}>
        <meshBasicMaterial attach="material" {...matSettings.text} map={sideBetTexture_2} opacity={0.5} />
        <planeGeometry attach="geometry" args={[450 * 1.4, 450 * 1.4]} />
      </mesh>}
      <group position={[-185, 0, 0]}>
        <mesh name="shoe_clear">
          <meshPhysicalMaterial attach="material" {...matSettings.clear} />
          <bufferGeometry attach="geometry" {...gltf.__$[2].geometry} />
        </mesh>
        <mesh name="shoe_black">
          <meshPhysicalMaterial attach="material" {...matSettings.black} />
          <bufferGeometry attach="geometry" {...gltf.__$[3].geometry} />
        </mesh>
        <mesh name="cards">
          <meshPhysicalMaterial attach="material" {...matSettings.card} />
          <bufferGeometry attach="geometry" {...gltf.__$[5].geometry} />
        </mesh>
        <mesh name="cards" position={[0, -0.5, 0]}>
          <meshBasicMaterial attach="material" {...matSettings.shadow} />
          <bufferGeometry attach="geometry" {...gltf.__$[6].geometry} />
        </mesh>
      </group>
      <mesh name="table">
        <meshPhysicalMaterial attach="material" {...matSettings.table} />
        <bufferGeometry attach="geometry" {...gltf.__$[4].geometry} />
      </mesh>
    </group>
  )
}

export default TableGeometry