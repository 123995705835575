import React, {useEffect} from 'react';
import './App.scss';
import setParentEventListeners from './events/parent';

function App() {
  useEffect(() => {
    setParentEventListeners();
  });

  return (
    <iframe
      title="blackjack-iframe"
      id="blackjack-iframe"
      src="/blackjack"
      height="1000"
      width="1400"
    />
  );
}


export default App;
